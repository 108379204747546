/* eslint quotes: 0 */

import { Constants } from "Utils/Constants";

// tslint:disable: no-hardcoded-credentials no-duplicate-string typedef max-line-length
export const localeHu = {
    // API
    "api.error.notFound": "A keresett elem nem található!",

    // COMMON
    "common.cancel": "Mégsem",
    "common.loading": "Betöltés…",
    "common.logout": "Kijelentkezés",
    "common.save": "Mentés",
    "common.send": "Küldés",
    "common.required": "Kötelező mező.",
    "common.requiredTestMail": "Kötelező mező. Több e-mail cím megadása szóközzel elválasztva.",
    "common.optional": "Opcionális megadás",
    "common.optionalField": "Opcionális mező.",
    "common.add": "Hozzáadás",
    "common.delete": "Törlés",
    "common.yes": "Igen",
    "common.no": "Nem",
    "common.present": "Van",
    "common.absent": "Nincs",
    "common.edit": "Szerkesztés",
    "common.form.basicData": "Alapadatok",
    "common.form.settings": "Beállítások",
    "common.form.content": "Tartalom",
    "common.form.metaData": "Meta adatok",
    "common.form.ogTitle": "og:title",
    "common.form.ogDescription": "og:description",
    "common.form.title": "meta title",
    "common.form.metaDescription": "meta description",
    "common.form.isActive.true": "Aktív",
    "common.form.isActive.false": "Inaktív",
    "common.form.preview": "Előnézet",
    "common.form.ogImage": "og:image",
    "common.form.evoProductDescription": "EVO termékleírás",
    "common.minCharacter": "Minimum {min} karakter.",
    "common.maxCharacter": "Maximum {max} karakter.",
    "common.statistics.title": "Statisztikai adatok",
    "common.statistics.createdAtLabel": "Létrehozás dátuma",
    "common.statistics.createdByLabel": "Létrehozta",
    "common.statistics.updatedAtLabel": "Utolsó módosítás dátuma",
    "common.statistics.updatedByLabel": "Utoljára módosította",
    "common.form.altTitleTag": "alt / title tag",
    "common.huf": "{huf} Ft",
    "common.hufPerPiece": "{huf} Ft/db",
    "common.create": "Létrehozás",
    "common.statistics.created_at": "Létrehozás dátuma",
    "common.statistics.created_by": "Létrehozta",
    "common.statistics.updated_at": "Utolsó módosítás dátuma",
    "common.statistics.updated_by": "Utoljára módosította",
    "common.selectedItems": "{selectedItems} KIJELÖLT ELEM",
    "common.move": "Mozgatás",
    "common.noOptions": "Nincs találat",
    "common.vipLevel.gold": "Arany",
    "common.vipLevel.silver": "Ezüst",
    "common.vipLevel.bronze": "Bronz",
    "common.vipLevel.basic": "Alap",
    "common.back": "Vissza",
    "common.nullFormValue": "Nincs megadva",
    "common.ok": "Ok",

    // COMPONENT
    "components.dataGrid.calendar.cancelButtonText": "Mégsem",
    "components.dataGrid.calendar.clearButtonText": "Törlés",
    "components.dataGrid.calendar.okButtonText": "Rendben",
    "components.dataGrid.calendar.todayButtonText": "Ma",
    "components.dataGrid.clear": "Ürít",
    "components.dataGrid.clearAll": "Ürít mindent",
    "components.dataGrid.columns": "Oszlopok",
    "components.dataGrid.contains": "Tartalmaz",
    "components.dataGrid.disable": "Tiltva",
    "components.dataGrid.dragHeaderToGroup": "Drag header to group",
    "components.dataGrid.empty": "Üres",
    "components.dataGrid.emptyText": "Nincs megjeleníthető elem",
    "components.dataGrid.enable": "Engedélyezve",
    "components.dataGrid.endsWith": "Végződik",
    "components.dataGrid.eq": "Egyenlő",
    "components.dataGrid.group": "Csoportosítás",
    "components.dataGrid.gt": "Nagyobb mint",
    "components.dataGrid.gte": "Nagyobb vagy egyenlő mint",
    "components.dataGrid.hideFilteringRow": "Hide filtering row",
    "components.dataGrid.loading": "Betöltés",
    "components.dataGrid.lockEnd": "Zárás vége",
    "components.dataGrid.lockStart": "Zárás kezdeze",
    "components.dataGrid.lt": "Kevesebb mint",
    "components.dataGrid.lte": "Kevesebb vagy egyenlő mint",
    "components.dataGrid.neq": "Nem egyenlő",
    "components.dataGrid.notContains": "Nem tartalmaz",
    "components.dataGrid.notEmpty": "Nem üres",
    "components.dataGrid.ofText": " / ",
    "components.dataGrid.pageText": "Oldal ",
    "components.dataGrid.perPageText": "Találatok száma",
    "components.dataGrid.showFilteringRow": "Show filtering row",
    "components.dataGrid.showingText": "Mutat ",
    "components.dataGrid.sortAsc": "Rendezés növekvő",
    "components.dataGrid.sortDesc": "Rendezés csökkenő",
    "components.dataGrid.startsWith": "Kezdődik",
    "components.dataGrid.ungroup": "Csoporttalanítás",
    "components.dataGrid.unlock": "Kinyitás",
    "components.dataGrid.unsort": "Rendezés törlése",

    "components.deleteDocumentModal.title": "Dokumentum törlése",
    "components.deleteDocumentModal.delete": "Törlés",
    "components.deleteDocumentModal.descriptionForce": "A törölni kívánt dokumentum hozzá van rendelve más entitásokhoz.<br /><br />Biztosan törölni szeretnéd?",
    "components.deleteDocumentModal.description": "Biztosan törölni szeretnéd a {file_name} nevű dokumentumot?",
    "components.deleteDocumentModal.deleteSucceed": "Dokumentum törlése sikeres!",

    "components.drawer.adminList": "Adminisztrátorok",
    "components.drawer.articleList": "Cikkszerkesztő",
    "components.drawer.clientList": "Regisztrált felhasználók",
    "components.drawer.dashboard": "Indítópult",
    "components.drawer.documentList": "Dokumentumok",
    "components.drawer.galleryList": "Képgaléria",
    "components.drawer.mediaLibrary": "Médiatár",
    "components.drawer.categoryList": "Cikk kategóriák",
    "components.drawer.users": "Felhasználók",
    "components.drawer.products": "Termékkezelő",
    "components.drawer.products.products": "Termékek",
    "components.drawer.products.productCategories": "Termékkategóriák",
    "components.drawer.products.productBrands": "Márkák",
    "components.drawer.products.productLists": "Termékszegmensek",
    "components.drawer.products.productParams": "Termékjellemzők",
    "components.drawer.products.productBundles": "Virtuális termékcsomagok",
    "components.drawer.products.productVariants": "Variánsok",
    "components.drawer.contents": "Tartalomszerkesztő",
    "components.drawer.contents.badges": "Plecsnik",
    "components.drawer.contents.banners": "Bannerek",
    "components.drawer.roleList": "Szerepkörök",
    "components.drawer.userList": "Regisztrált felhasználók",
    "components.drawer.redirectionList": "URL átirányítások",
    "components.drawer.jobs": "Állások",
    "components.drawer.jobs.persons": "Személyek",
    "components.drawer.jobs.benefit": "Juttatások",
    "components.drawer.jobs.departments": "Szervezeti egységek",
    "components.drawer.jobs.divisions": "Üzleti területek",
    "components.drawer.jobs.successStories": "Sikertörténetek",
    "components.drawer.settings": "Beállítások",
    "components.drawer.settings.mailTemplates": "Tranzakciós levelek",
    "components.drawer.contents.pages": "Oldalszerkesztő",
    "components.drawer.shipping": "Szállításkezelő",
    "components.drawer.shipping.methods": "Szállítási módok",
    "components.drawer.payment.methods": "Fizetési módok",
    "components.drawer.orderList": "Megrendelések",
    "components.drawer.contents.websiteNotifications": "Figyelmeztető sávok",
    "components.drawer.storeOrderList": "Bolti átvételek",
    "components.drawer.calendarDaysList": "Ünnepnapok",
    "components.drawer.systemSettingsList": "Rendszerparaméterek",
    "components.drawer.adminLogs": "Admin naplózás",
    "components.drawer.maintenanceList": "Karbantartások",
    "components.drawer.todoList": "Todo-k",
    "components.drawer.contents.menus": "Menüszerkesztő",
    "components.drawer.ratingList": "Értékelések",
    "components.drawer.cacheManagement": "Cache kezelés",
    "components.drawer.campaignList": "Negyedéves kampányok",
    "components.drawer.giftCardOrderList": "Digitális ajándékkártya",
    "components.drawer.subscriptionList": "Pelenka-előfizetések",

    "components.inputs.mediaLibraryImageInput.selectImage": "Kép kiválasztása",

    "components.inputs.remoteSelect.notSelected": "- Válassz ki egy elemet -",

    "components.inputs.richTextEditor.config.gallery.toolbarLabel": "Galéria",
    "components.inputs.richTextEditor.config.gallery.editButtonLabel": "Galéria szerkesztése",
    "components.inputs.richTextEditor.config.infoImage.toolbarLabel": "Kép",
    "components.inputs.richTextEditor.config.infoImage.descriptionLabel": "Leírás",
    "components.inputs.richTextEditor.config.infoImage.sourceLabel": "Kredit",
    "components.inputs.richTextEditor.config.heading.options.highlighted.title": "Kiemelt",

    "components.inputs.richTextEditor.galleryList.title": "Galéria beszúrása",
    "components.inputs.richTextEditor.galleryList.insert": "Beszúrás",
    "components.inputs.richTextEditor.galleryList.search": "Keresés",
    "components.inputs.richTextEditor.galleryList.add": "Új galéria",
    "components.inputs.richTextEditor.galleryList.emptyList": "Nincs a kulcsszónak megfelelő elem",

    "components.inputs.richTextEditor.galleryModal.create.title": "Galéria létrehozása",
    "components.inputs.richTextEditor.galleryModal.create.succeed": "Galéria létrehozása sikeres!",
    "components.inputs.richTextEditor.galleryModal.create.failed": "Galéria létrehozása sikertelen!",
    "components.inputs.richTextEditor.galleryModal.edit.title": "Galéria szerkesztése",
    "components.inputs.richTextEditor.galleryModal.edit.succeed": "Galéria szerkesztése sikeres!",
    "components.inputs.richTextEditor.galleryModal.edit.failed": "Galéria szerkesztése sikertelen!",
    "components.inputs.richTextEditor.galleryModal.delete.succeed": "Galéria törlése sikeres!",
    "components.inputs.richTextEditor.galleryModal.delete.failed": "Galéria törlése sikertelen!",
    "components.inputs.richTextEditor.galleryModal.addFromMediaLibrary": "hozzáadás a médiatárból",
    "components.inputs.richTextEditor.galleryModal.tab.content": "Tartalom",
    "components.inputs.richTextEditor.galleryModal.tab.sort": "Sorrend",

    "components.inputs.richTextEditor.config.cdnImage.toolbarLabel": "Kép",

    "components.prompt.title": "Oldal elhagyása",
    "components.prompt.description": "Az oldal elhagyását követően az el nem mentett változások el fognak veszni.<br /><br />Biztos elhagyod az oldalt?",
    "components.prompt.leave": "Elvetés",

    "components.addProductDialog.title": "Termékek",
    "components.addBadgeDialog.title": "Plecsnik",
    "components.addBadgeDialog.createBadge": "Új plecsni",

    "components.statisticsPaper.title": "Statisztikai adatok",
    "components.statisticsPaper.createdAtLabel": "Létrehozás dátuma",
    "components.statisticsPaper.createdByLabel": "Létrehozta",
    "components.statisticsPaper.updatedAtLabel": "Utolsó módosítás dátuma",
    "components.statisticsPaper.updatedByLabel": "Utoljára módosította",

    "components.listItemActive.isVisbleInTheFuture": "Aktív, jelenleg nem megjelenő",
    "components.listItemActive.active": "Aktív, jelenleg megjelenő",
    "components.listItemActive.inactive": "Inaktív",

    "components.previewDialog.loadFailed": "Előnézet betöltése sikertelen!",
    "components.previewDialog.mobileView": "Mobil nézet",
    "components.previewDialog.tabletView": "Tablet nézet",
    "components.previewDialog.desktopView": "Számítógép nézet",
    "components.previewDialog.previewTitle": "Előnézet",

    "components.productListAddDialog.title": "Termékszegmens kiválasztása",
    "components.productListAddDialog.createProductList": "Új szegmens",
    "components.bannerAddDialog.title": "Banner kiválasztása",
    "components.bannerAddDialog.createBanner": "Új banner",

    "components.addProductVariantChildDialog.title": "Termékek",

    "components.correctOrderItemsDialog.title": "Helyesbítő számla",

    "components.correctionDataGrid.quantityInputHeader": "Helyesbítési menny.",

    // ENUMS
    "enums.permission.MANAGE_ADMINS": "Adminok kezelése",
    "enums.permission.MANAGE_CATEGORIES": "Kategóriák kezelése",
    "enums.permission.MANAGE_CONTENT": "Cikkek kezelése",
    "enums.permission.MANAGE_DOCUMENTS": "Dokumentumok kezelése",
    "enums.permission.MANAGE_GALLERIES": "Galériák kezelése",
    "enums.permission.MANAGE_CAREER_BENEFITS": "Juttatások kezelése",
    "enums.permission.MANAGE_CAREER_DEPARTMENTS": "Szervezeti egységek kezelése",
    "enums.permission.MANAGE_CAREER_DIVISIONS": "Üzleti területek kezelése",
    "enums.permission.MANAGE_CAREER_PERSONS": "Karrier-személyek kezelése",
    "enums.permission.MANAGE_CAREER_SUCCESS_STORIES": "Sikersztorik kezelése",
    "enums.permission.MANAGE_REDIRECTIONS": "Átirányítások kezelése",

    "enums.validatorMessage.invalidEmail": "Érvénytelen e-mail cím!",
    "enums.validatorMessage.invalidNewPassword": "Nem megfelelő jelszó!",
    "enums.validatorMessage.invalidProfileName": "Túl rövid a név! Minimum 3 karakter legyen!",
    "enums.validatorMessage.invalidCategoryTitle": "A címnek legalább 3, maximum 255 karakternek kell lennie!",
    "enums.validatorMessage.passwordNotMatch": "A jelszavak nem egyeznek!",
    "enums.validatorMessage.required": "A mező kitöltése kötelező!",
    "enums.validatorMessage.invalidPermissionsLength": "Legalább egy jogosultság választása kötelező!",
    "enums.validatorMessage.invalidGalleryImagesLength": "Legalább egy képet hozzá kell adni a galériához!",
    "enums.validatorMessage.invalidContentTitle": "A címnek legalább 3 karakternek kell lennie!",
    "enums.validatorMessage.ogTitleTooLong": `Túl hosszú az og:title! Maximum ${Constants.ogTitleMaxLength} karakter legyen!`,
    "enums.validatorMessage.ogDescriptionTooLong": `Túl hosszú a leírás! Maximum ${Constants.ogDescriptionMaxLength} karakter legyen!`,
    "enums.validatorMessage.metaTitleTooLong": `Túl hosszú a title! Maximum ${Constants.metaTitleMaxLength} karakter legyen!`,
    "enums.validatorMessage.metaDescriptionTooLong": `Túl hosszú a leírás! Maximum ${Constants.metaDescriptionMaxLength} karakter legyen!`,
    "enums.validatorMessage.altTitleTooLong": `Túl hosszú a cím! Maximum ${Constants.altTitleMaxLength} karakter legyen!`,
    "enums.validatorMessage.bannerImageTitleTooLong": `Túl hosszú a cím! Maximum ${Constants.bannerImageTitleMaxLength} karakter legyen!`,
    "enums.productImageSort.evo": "EVO",
    "enums.productImageSort.brandbank": "Brandbank",
    "enums.productImageSort.webshop_admin": "Webshop admin",
    "enums.validatorMessage.startDateCantBeInThePast": "A kezdődátum nem lehet múltbeli!",
    "enums.productListMode.auto": "automatikus",
    "enums.productListMode.manual": "kézi",
    "enums.productListType.offer": "Termékajánlatok",
    "enums.productListType.promotion": "Promóciós lista",
    // TODO: placeholder
    "enums.badgeType.other": "Egyéb",
    "enums.badgeType.discounted": "EVO árkedvezmény",
    "enums.badgeType.top": "Top termék",
    "enums.badgeType.low_stock": "Alacsony készlet",
    "enums.badgeType.product_param": "Terméktulajdonsághoz kötött",
    "enums.badgeType.new_product": "Új termék",
    "enums.badgeType.promotion": "Promóció",
    "enums.badgeType.delisting": "Kiárusítás",
    "enums.productFilterType.product_param": "Mentesség alapján",
    "enums.productFilterType.product_category": "Kategória alapján",
    "enums.productFilterType.brand": "Márka alapján",
    "enums.productFilterType.created": "Bekerülés dátuma alapján",
    "enums.productFilterType.rating": "Termékértékelés alapján",
    "enums.productFilterType.price_type": "EVO ár típus alapján",
    "enums.productFilterType.clpe_promotion_type": "Kedvezményplecsni alapján",
    "enums.productFilterType.promotion": "Promóció alapján",
    "enums.productFilterType.new": "Új termékek alapján",
    "enums.productFilterType.product_type": "Termék típus alapján",
    "enums.productFilterOperator.eq": "Egyenlő",
    "enums.productFilterOperator.gte": "Későbbi/feletti vagy egyenlő",
    "enums.productFilterOperator.lte": "Korábbi/kevesebb vagy egyenlő",
    "enums.bannerType.normal": "Általános",
    "enums.bannerType.product_list": "Termék lista",
    "enums.bannerType.product": "Termék",
    "enums.bannerAlign.center": "Középre",
    "enums.bannerAlign.right": "Jobbra",
    "enums.bannerAlign.left": "Balra",

    "enums.orderStatusEnum.open": "Beérkezett megrendelés",
    "enums.orderStatusEnum.in_progress": "Teljesítés folyamatban",
    "enums.orderStatusEnum.completed": "Sikeresen fizetve",
    "enums.orderStatusEnum.canceled": "Sikertelen fizetés",
    "enums.orderStatusEnum.modified": "Módosítva",

    "validator.endDateCantBeBeforeStart": "A végedátum nem lehet a kezdeti dátumnál előbb",
    "validator.startDateCantBeAfterEnd": "A kezdeti dátum nem lehet a végedátumnál később",
    "validator.dateOverlaps": "A dátum párhuzamos más dátummal/dátumokkal",
    "enums.validatorMessage.invalidPersonName": "A névnek legalább 3, maximum 255 karakternek kell lennie!",
    "enums.validatorMessage.invalidPersonPosition": "A pozíciónak legalább 3, maximum 255 karakternek kell lennie!",
    "enums.validatorMessage.invalidBenefitName": "A névnek legalább 3, maximum 255 karakternek kell lennie!",
    "enums.validatorMessage.invalidDepartmentName": "A névnek legalább 3, maximum 255 karakternek kell lennie!",
    "enums.validatorMessage.invalidDivisionName": "A névnek maximum 255 karakternek kell lennie!",
    "enums.validatorMessage.shippingMethodLeadTooLong": `A leírás maximum ${Constants.shippingMethodLeadMaxLength} karakter lehet!`,
    "enums.validatorMessage.paymentMethodDescriptionTooLong": `A leírás maximum ${Constants.paymentMethodDescriptionMaxLength} karakter lehet!`,
    "enums.validatorMessage.arrayCantBeEmpty": "Legalább 1 elem kötelező!",
    "enums.validatorMessage.redirectionToMustBeAbsoluteUrl": "Abszolút URL-t kell megadni, pl.: 'https://www.pelda.hu/'!",
    "enums.validatorMessage.redirectionToMustBeRelativeUrl": "Relatív URL-t kell megadni, pl.: '/kapcsolat'!",
    "enums.validatorMessage.slug": "Az url nem tartalmazhat ékezetes karaktereket!",
    "enums.validatorMessage.urlFormat": "Az url csak betűkből, számokból és kötőjelekből állhat!",
    "enums.validatorMessage.valueMustBeANumber": `Kérjük egy számot adjon meg!`,

    "enums.pageBlockType.banner": "Banner blokk",
    "enums.pageBlockType.product_list": "Termék lista blokk",
    "enums.pageBlockType.html": "Szöveg blokk",
    "enums.pageBlockType.newsletter": "Hírlevél feliratkozás",
    "enums.pageBlockType.brand_list": "Márka lista",
    "enums.pageBlockType.recommended_products": "Neked javasoljuk",
    "enums.pageBlockType.homebox": "Miért a Rossmann?",

    "enums.extendedPageBlockBannerLayout.featured": "Kiemelt",
    "enums.extendedPageBlockBannerLayout.single": "1 db",
    "enums.pageBlockBannerLayout.full": "Slider",
    "enums.pageBlockBannerLayout.half_half": "1/2 1/2",
    "enums.pageBlockBannerLayout.quarter_threequarter": "1/4 3/4",
    "enums.pageBlockBannerLayout.threequarter_quarter": "3/4 1/4",
    "enums.pageBlockBannerLayout.quarter_quarter_half": "1/4 1/4 1/2",
    "enums.pageBlockBannerLayout.half_quarter_quarter": "1/2 1/4 1/4",
    "enums.pageBlockBannerLayout.quarter_quarter_quarter_quarter": "1/4 1/4 1/4 1/4",

    "enums.redirectionType.external": "Külső",
    "enums.redirectionType.internal": "Belső",
    "enums.redirectionType.siteToSite": "Oldalak közötti",

    "enums.priceType.promo": "Promóció",
    "enums.priceType.delisting": "Kiárusítás",

    "enums.clpePromotionType.rossmann_plus": "Rossmann Plus kedvezmény",
    "enums.clpePromotionType.rossmann_plus_baby": "Babakártyával igénybe vehető",
    "enums.clpePromotionType.gratis_product": "Ajándék termék",
    "enums.clpePromotionType.one_plus_one": "1+1 kedvezmény",
    "enums.clpePromotionType.two_plus_one": "2+1 kedvezmény",
    "enums.clpePromotionType.glamour": "Glamour kupon",
    "enums.clpePromotionType.joy": "Joy kupon",
    "enums.clpePromotionType.qty_discount": "Mennyiségi kedvezmény",
    "enums.clpePromotionType.free_shipping": "Ingyenes szállítás",
    "enums.clpePromotionType.black_friday": "Black Friday",
    "enums.clpePromotionType.baby_coupon": "Baba-Mama kupon kedvezmény",

    "enums.bankTransferStatus.partially_paid": "Részlegesen beérkezett",
    "enums.bankTransferStatus.paid": "Beérkezett",

    "enums.logActionType.created": "Létrejött",
    "enums.logActionType.updating": "Módosult",
    "enums.logActionType.deleting": "Törlődött",

    "enums.modelType.product": "Termék",
    "enums.modelType.productPrice": "Termékár",
    "enums.modelType.productStock": "Termék készlet",
    "enums.modelType.banner": "Banner",
    "enums.modelType.order": "Rendelés",
    "enums.modelType.orderItem": "Rendeléstétel",
    "enums.modelType.orderPayment": "Fizetés",
    "enums.modelType.invoice": "Számla",
    "enums.modelType.shipment": "Szállítás",
    "enums.modelType.admin": "Admin",

    "enums.todoStatus.todo": "Folyamatban",
    "enums.todoStatus.done": "Kész",

    "enums.productType.bundle": "Termékcsomag",
    "enums.productType.normal": "Normál",
    "enums.productType.variant": "Variálható",

    "enums.rossmannPlusVipLevel.NONE": "Nem Rossmann+ tag",
    "enums.rossmannPlusVipLevel.BASIC": "Rossmann+",
    "enums.rossmannPlusVipLevel.BRONZE": "Bronz",
    "enums.rossmannPlusVipLevel.SILVER": "Ezüst",
    "enums.rossmannPlusVipLevel.GOLD": "Arany",

    "enums.clientType.desktop": "Desktop",
    "enums.clientType.mobile": "Mobil",
    "enums.clientType.android": "Android",
    "enums.clientType.ios": "iOS",

    "enums.fulfillmentTypeEnum.DEFAULT": "Egyik sem",
    "enums.fulfillmentTypeEnum.ROSSMANN_A2": "Saját",
    "enums.fulfillmentTypeEnum.DHL": "DHL",

    "enums.invoiceTypeEnum.normal": "Normál",
    "enums.invoiceTypeEnum.proforma": "Proforma",
    "enums.invoiceTypeEnum.storno": "Sztornó",
    "enums.invoiceTypeEnum.corrective": "Helyesbítő",

    "enums.productListSortFriendlyValues.ratingAsc": "Értékelés szerint növekvő",
    "enums.productListSortFriendlyValues.ratingDesc": "Értékelés szerint csökkenő",
    "enums.productListSortFriendlyValues.orderedAsc": "Rendelésszám szerint növekvő",
    "enums.productListSortFriendlyValues.orderedDesc": "Rendelésszám szerint csökkenő",
    "enums.productListSortFriendlyValues.nameAsc": "Név szerint növekvő",
    "enums.productListSortFriendlyValues.nameDesc": "Név szerint csökkenő",
    "enums.productListSortFriendlyValues.priceAsc": "Ár szerint növekvő",
    "enums.productListSortFriendlyValues.priceDesc": "Ár szerint csökkenő",
    "enums.productListSortFriendlyValues.priceUnitAsc": "Egységár szerint növekvő",
    "enums.productListSortFriendlyValues.priceUnitDesc": "Egységár szerint csökkenő",
    "enums.productListSortFriendlyValues.none": "Nincs rendezés",

    "enums.packageType.crate": "Láda",

    // PAGES
    "pages.admin.create.failed": "Meghívó küldése sikertelen!",
    "pages.admin.create.succeed": "Meghívó küldése sikeres!",
    "pages.admin.create.title": "Új admin felhasználó",
    "pages.admin.create.breadcrumb": "Új admin felhasználó",

    "pages.admin.edit.failed": "Felhasználó módosítása sikertelen!",
    "pages.admin.edit.succeed": "Felhasználó módosítása sikeres!",
    "pages.admin.edit.breadcrumb": "Adminisztrátor szerkesztése",

    "pages.admin.form.accountData": "Fiók adatok",
    "pages.admin.form.active": "Aktív",
    "pages.admin.form.changePassword": "Jelszó módosítása",
    "pages.admin.form.email.label": "E-mail cím",
    "pages.admin.form.phone.label": "Telefonszám",
    "pages.admin.form.invite": "Meghívó küldése",
    "pages.admin.form.name.label": "Név",
    "pages.admin.form.roles": "Szerepkörök",
    "pages.admin.form.permissions": "Jogosultságok",
    "pages.admin.form.permissionsRequirement": "Legalább egy jogosultság választása kötelező!",
    "pages.admin.form.status": "Admin felhasználó státusza",
    "pages.admin.form.lastLoginLabel": "Utolsó belépés időpontja",
    "pages.admin.form.idLabel": "Admin user ID",
    "pages.admin.form.rolesHint": "Legalább egy szerepkör választása kötelező",
    "pages.admin.form.storeIdLabel": "Bolt",

    "pages.admin.edit.deleteModal.title": "Adminisztrátor törlése",
    "pages.admin.edit.deleteModal.description": "Biztosan törölni szeretné az adminisztrátort?",
    "pages.admin.edit.deleteSucceed": "Adminisztrátor törlése sikeres!",
    "pages.admin.edit.deleteError": "Adminisztrátor törlése sikertelen!",

    "pages.adminList.add": "Új admin felhasználó",
    "pages.adminList.grid.column.email": "E-mail cím",
    "pages.adminList.grid.column.lastLogin": "Utolsó belépés",
    "pages.adminList.grid.column.role": "Szerepkör",
    "pages.adminList.grid.column.id": "ID",
    "pages.adminList.grid.column.name": "Név",
    "pages.adminList.grid.column.status": "Státusz",
    "pages.adminList.grid.column.status.active": "Aktív",
    "pages.adminList.grid.column.status.inactive": "Inaktív",
    "pages.adminList.title": "Admin felhasználók",
    "pages.adminList.breadcrumb": "Adminisztrátorok",

    "pages.roleList.title": "Szerepkörök",
    "pages.roleList.addButton": "Új szerepkör",
    "pages.roleList.grid.column.id": "ID",
    "pages.roleList.grid.column.title": "Szerepkör neve",

    "pages.role.create.onSubmit.success": "Szerepkör létrehozása sikeres!",
    "pages.role.create.onSubmit.error": "Szerepkör létrehozása sikertelen!",
    "pages.role.createTitle": "Új szerepkör",
    "pages.role.edit.breadcrumb": "Szerepkörök",
    "pages.role.edit.onSubmit.succeed": "Szerepkör szerkesztése sikeres!",
    "pages.role.edit.onSubmit.error": "Szerepkör szerkesztése sikertelen!",
    "pages.role.delete.onSubmit.succeed": "Szerepkör törlése sikeres!",
    "pages.role.delete.onSubmit.error": "Szerepkör törlése sikertelen!",
    "pages.role.deleteModal.title": "Szerepkör törlése",
    "pages.role.deleteModal.description": "Biztosan törölni szeretné a szerepkört?",
    "pages.role.form.titleLabel": "Szerepkör neve",
    "pages.role.form.nameLabel": "Szerepkör slug",
    "pages.role.form.idLabel": "Szerepkör ID",
    "pages.role.form.permissionsHeading": "Jogosultságok",

    "pages.role.form.group.product": "Termékek",
    "pages.role.form.group.checkout": "Pénztár",
    "pages.role.form.group.order": "Rendelések",
    "pages.role.form.group.user": "Felhasználók",
    "pages.role.form.group.admin": "Adminisztrátorok",
    "pages.role.form.group.content": "Cikkek",
    "pages.role.form.group.career": "Karrier",
    "pages.role.form.group.system": "Rendszer",
    "pages.role.form.group.select.none": "Nincs kijelölés",
    "pages.role.form.group.select.all": "Összes kijelölése",

    "pages.benefitList.title": "Juttatások",
    "pages.benefitList.grid.column.id": "ID",
    "pages.benefitList.grid.column.title": "Név",
    "pages.benefitList.grid.column.created_at": "Létrehozás dátuma",
    "pages.benefitList.grid.column.updated_at": "Módosítás dátuma",
    "pages.benefitList.add": "Új juttatás",
    "pages.benefitList.breadcrumb": "Juttatások",
    "pages.benefit.edit.breadcrumb": "Juttatás szerkesztése",
    "pages.benefit.edit.succeed": "Juttatás szerkesztése sikeres!",
    "pages.benefit.edit.failed": "Juttatás szeresztése sikertelen!",
    "pages.benefit.create.succeed": "Juttatás létrehozása sikeres!",
    "pages.benefit.create.failed": "Juttatás létrehozása sikertelen!",
    "pages.benefit.create.breadcrumb": "Új juttatás létrehozása",
    "pages.benefit.create.title": "Új juttatás létrehozása",
    "pages.benefit.form.title.label": "Név",
    "pages.benefit.form.image.label": "Kép",
    "pages.benefit.edit.deleteModal.title": "Juttatás törlése",
    "pages.benefit.edit.deleteModal.description": "Biztosan törlöd a juttatást?",

    "pages.category.edit.succeed": "Cikk kategória szerkesztése sikeres!",
    "pages.category.edit.failed": "Cikk kategória szerkesztése sikertelen!",
    "pages.category.edit.breadcrumb": "Cikk kategória szerkesztése",

    "pages.category.create.breadcrumb": "Cikk kategória létrehozása",
    "pages.category.create.succeed": "Cikk kategória létrehozása sikeres!",
    "pages.category.create.failed": "Cikk kategória létrehozása sikertelen!",
    "pages.category.create.title": "Cikk kategória létrehozása",

    "pages.category.form.data": "Adatok",
    "pages.category.form.id.label": "ID",
    "pages.category.form.title.label": "Megnevezés",
    "pages.category.form.lead.label": "Lead",
    "pages.category.form.url.label": "URL",
    "pages.category.form.metaTitle.label": "og:title",
    "pages.category.form.metaDescription.label": "og:description",
    "pages.category.form.metaKeywords.label": "og:keywords",

    "pages.categoryList.title": "Cikk kategóriák",
    "pages.categoryList.add": "Új cikk kategória",
    "pages.categoryList.grid.column.id": "ID",
    "pages.categoryList.grid.column.title": "Megnevezés",
    "pages.categoryList.breadcrumb": "Cikk kategóriák",

    "pages.productBrandList.title": "Márkák",
    "pages.productBrandList.grid.column.id": "ID",
    "pages.productBrandList.grid.column.name": "Márka neve",
    "pages.productBrandList.grid.column.is_rossmann": "Saját márka",
    "pages.productBrandList.grid.column.is_logo": "Logo",
    "pages.productBrandList.grid.column.updated_at": "Módosítás dátuma",
    "pages.productBrandList.grid.column.is_public": "Státusz",

    "pages.productBrand.breadcrumb.title": "Márkák",
    "pages.productBrand.onSubmit.success": "Márka módosítása sikeres!",
    "pages.productBrand.onSubmit.error": "Márka módosítása sikertelen!",
    "pages.productBrand.form.titleLabel": "Márka neve",
    "pages.productBrand.form.idLabel": "ID",
    "pages.productBrand.form.evoNameLabel": "Márka neve (Forrás:ERP)",
    "pages.productBrand.form.nameLabel": "Márka neve",
    "pages.productBrand.form.slugLabel": "slug",
    "pages.productBrand.form.statusLabel": "Státusz a websiteon",
    "pages.productBrand.form.isActiveLabel": "Aktív",
    "pages.productBrand.form.logoLabel": "Logo",
    "pages.productBrand.form.logo.helperText": "200 * 200 px képarány javasolt.",
    "pages.productBrand.form.productsSection": "Termékek",
    "pages.productBrand.form.isRossmannLabel": "Saját márka",

    "pages.productCatalog.title": "Termékek",
    "pages.productCatalog.grid.column.id": "ID",
    "pages.productCatalog.grid.column.lfdnr": "CSZ",
    "pages.productCatalog.grid.column.name": "Termék név",
    "pages.productCatalog.grid.column.main_categories": "Főkategória",
    "pages.productCatalog.grid.column.categories": "Közvetlen kategória",
    "pages.productCatalog.grid.column.is_visible": "Státusz",
    "pages.productCatalog.grid.column.is_description": "Leírás",
    "pages.productCatalog.grid.column.is_image": "Kép",
    "pages.productCatalog.grid.column.ean": "EAN",
    "pages.productCatalog.grid.column.prices": "Ár",
    "pages.productCatalog.grid.column.stock_qty": "Mennyiség",
    "pages.productCatalog.grid.column.images": "Kép",

    "pages.productCategoryList.title": "Kategóriák",
    "pages.productCategoryList.grid.column.id": "ID",
    "pages.productCategoryList.grid.column.title": "Megnevezés",
    "pages.productCategoryList.breadcrumb": "Kategóriák",
    "pages.productCategoryList.productCategoryRow.statusIconActive.true": "Státusz a websiteon: Aktív",
    "pages.productCategoryList.productCategoryRow.statusIconActive.false": "Státusz a websiteon: Inaktív",
    "pages.productCategoryList.productCategoryRow.featured": "Kiemelt kategória",
    "pages.productCategoryList.productCategoryRow.reorder": "Kategóriák sorrendezése",
    "pages.productCategoryList.reorderCategoriesModal.title": "{name} kategóriáinak sorrendezése",
    "pages.productCategoryList.reorderCategoriesModal.titleAll": "Főkategóriák sorrendezése",
    "pages.productCategoryList.reorderCategoriesModal.description": "Húzd a kategóriákat a kívánt pozíciókra!",
    "pages.productCategoryList.reorderCategoriesModal.success": "Kategóriák sorrendezése sikeres!",

    "pages.productCategory.breadcrumb.title": "Termékkategóriák",
    "pages.productCategory.onSubmit.success": "Termékkategória módosítása sikeres!",
    "pages.productCategory.onSubmit.error": "Termékkategória módosítása sikertelen!",
    "pages.productCategory.form.categoryLabel": "Kategória neve",
    "pages.productCategory.form.categoryIconLabel": "Kategória ikon",
    "pages.productCategory.form.icon.helperText": "200 * 200 px képarány javasolt.",
    "pages.productCategory.form.descriptionLabel": "Leírás",
    "pages.productCategory.form.bannerData": "Banner",
    "pages.productCategory.form.bannerEnabledLabel": "Menüben megjelenik a banner",
    "pages.productCategory.form.bannerImageLabel": "Banner kép",
    "pages.productCategory.form.bannerImage.helperText": "680 * 1530 px képarány javasolt.",
    "pages.productCategory.form.bannerLinkNewTabLabel": "Megnyitás új ablakban",
    "pages.productCategory.form.bannerLinkLabel": "Banner link",
    "pages.productCategory.form.statusLabel": "Státusz a websiteon",
    "pages.productCategory.form.isActiveLabel": "Aktív",
    "pages.productCategory.form.includeInMenuLabel": "Menüben megjelenik",
    "pages.productCategory.form.isFeaturedLabel": "Kiemelt kategória",
    "pages.productCategory.form.feedLabel": "Feedekben megjelenik",
    "pages.productCategory.form.feedFacebookLabel": "Facebook CSV",
    "pages.productCategory.form.feedGoogleLabel": "Google CSV",
    "pages.productCategory.form.feedGoogleMerchantLabel": "Google Merchant Center XML",
    "pages.productCategory.form.feedDsaLabel": "DSA",
    "pages.productCategory.form.slugLabel": "slug",
    "pages.productCategory.form.productSortByLabel": "Termékek rendezése",
    "pages.productCategory.form.bannerActiveFromLabel": "Megjelenés kezdete",
    "pages.productCategory.form.bannerActiveToLabel": "Megjelenés vége",
    "pages.productCategory.form.banners.tabLabel": "{index}. banner",
    "pages.productCategory.form.banners.newBanner": "Új banner",
    "pages.productCategory.form.banners.delete": "Banner törlése",
    "pages.productCategory.form.banners.noBanners": "Nincs hozzáadott banner.",

    "components.drawer.products.productGratis": "Mintatermékek",
    "pages.productGratis.title": "Mintatermékek",
    "pages.productGratis.breadcrumb.title": "Mintatermékek",
    "pages.productGratis.grid.column.id": "ID",
    "pages.productGratis.grid.column.lfdnr": "CSZ",
    "pages.productGratis.grid.column.ean": "EAN",
    "pages.productGratis.grid.column.name": "Termék név",
    "pages.productGratis.grid.column.main_categories": "Főkategória",
    "pages.productGratis.grid.column.is_visible": "Státusz",
    "pages.productGratis.grid.column.is_image": "Kép",
    "pages.productGratis.grid.column.stock_qty": "Mennyiség",
    "pages.productGratis.form.activeDate": "Érvényesség",
    "pages.productGratis.grid.column.active_from": "Érvényesség kezdete",
    "pages.productGratis.grid.column.active_to": "Érvényesség vége",
    "pages.productGratis.edit.succeed": "Sikeres módosítás",
    "pages.productGratis.edit.failed": "Sikertelen módosítás",

    "pages.product.breadcrumb.title": "Termékek",
    "pages.product.onSubmit.success": "Termék módosítása sikeres!",
    "pages.product.onSubmit.error": "Termék módosítása sikertelen!",
    "pages.product.form.tabs.general": "Általános",
    "pages.product.form.tabs.data": "Adatok",
    "pages.product.form.tabs.stock": "Készlet",
    "pages.product.form.tabs.log": "Napló",
    "pages.product.form.statusLabel": "Státusz a websiteon",
    "pages.product.form.isActiveLabel": "Engedélyezett",
    "pages.product.form.orderQtyMaxCheckboxLabel": "Eladható darabszám korlátozás",
    "pages.product.form.orderQtyMaxLabel": "Limit (darabszám)",
    "pages.product.form.tabs.data.pricesHeader": "Árak",
    "pages.product.form.tabs.data.column.price": "Ár",
    "pages.product.form.tabs.data.column.price_unit": "Egységár",
    "pages.product.form.tabs.data.column.active_from": "Érvényesség kezdete",
    "pages.product.form.tabs.data.column.active_to": "Érvényesség vége",
    "pages.product.form.tabs.stock.column.qty": "Elérhető készlet",
    "pages.product.form.tabs.stock.column.updated_at": "Lekérdezés dátuma",
    "pages.product.form.badgesHeading": "Plecsnik",
    "pages.product.form.badgesLabel": "Plecsnik",
    "pages.product.productImagesSortList.sourceLabel": "Forrás: {source}",
    "pages.product.productImagesSortList.setPrimaryLabel": "Elsődleges termékfotónak állítom",

    "pages.product.form.tabs.general.name": "Termék neve",
    "pages.product.form.tabs.general.id": "ID",
    "pages.product.form.tabs.general.lfdnr": "Cikkszám",
    "pages.product.form.tabs.general.ean": "EAN",
    "pages.product.form.tabs.general.categories": "Kategóriák",
    "pages.product.form.slugLabel": "URL",
    "pages.product.form.imagesHeading": "Termékképek",
    "pages.product.form.healthFundLabel": "Egészségpénztár számlára elszámolható",
    "pages.product.form.ogImageLabel": "og:image",
    "pages.product.form.ogTitleLabel": "og:title",
    "pages.product.form.ogDescriptionLabel": "og:description",
    "pages.product.form.metaDescriptionLabel": "meta description",
    "pages.product.form.brandBankDescriptionLabel": "Leírás Brandbank",
    "pages.product.form.descriptionLabel": "Leírás (alt)",
    "pages.product.form.otherParamsHeading": "További adatok",
    "pages.product.form.useEvoProductDescriptionLabel": "EVO termékleírás használata",
    "pages.product.form.imagePosition360Label": "360 fokos kép pozíciója",
    "pages.product.form.brandbankExcludeLabel": "Brandbank adatok felülírása",
    "pages.product.form.subscriptionSettings": "Előfizetés beállítások",
    "pages.product.form.sizeClass": "Méretosztály megadása",
    "pages.productListList.title": "Termékszegmensek",
    "pages.productListList.addButton": "Új termékszegmens",
    "pages.productListList.add.offer": "Termékajánlatok",
    "pages.productListList.add.promotion": "Promóciós lista",
    "pages.productListList.grid.column.id": "ID",
    "pages.productListList.grid.column.name": "Szegmens megnevezése",
    "pages.productListList.grid.column.mode": "Hozzáadás módja",
    "pages.productListList.grid.column.active_from": "Megjelenés kezdete",
    "pages.productListList.grid.column.active_to": "Megjelenés vége",
    "pages.productListList.grid.column.is_active": "Státusz",
    "pages.productListList.grid.column.status.active": "Aktív",
    "pages.productListList.grid.column.status.inactive": "Inaktív",

    "pages.productList.add": "Új termékszegmens",
    "pages.productList.create.onSubmit.success": "Termékszegmens létrehozása sikeres!",
    "pages.productList.create.onSubmit.error": "Termékszegmens létrehozása sikertelen!",
    "pages.productList.edit.onSubmit.success": "Termékszegmens módosítása sikeres!",
    "pages.productList.edit.onSubmit.error": "Termékszegmens módosítása sikertelen!",
    "pages.productList.edit.breadcrumb": "Termékszegmensek",
    "pages.productList.edit.deleteModal.title": "Termékszegmens törlése",
    "pages.productList.edit.deleteModal.description": "Biztosan törölni szeretné a termékszegmenst?",
    "pages.productList.edit.deleteSucceed": "Termékszegmens törlése sikeres!",
    "pages.productList.edit.deleteError": "Termékszegmens törlése sikertelen!",

    "pages.productList.form.idLabel": "ID",
    "pages.productList.form.typeLabel": "Típus",
    "pages.productList.form.nameLabel": "Szegmens megnevezése",
    "pages.productList.form.slugLabel": "URL",
    "pages.productList.form.statusLabel": "Státusz a websiteon",
    "pages.productList.form.isActiveLabel": "Aktív",
    "pages.productList.form.activeFromLabel": "Megjelenés kezdete",
    "pages.productList.form.activeToLabel": "Megjelenés vége",
    "pages.productList.form.titleLabel": "Cím",
    "pages.productList.form.subtitleLabel": "Alcím",
    "pages.productList.form.imageLabel": "Desktop kép",
    "pages.productList.form.image.helperText": "1700 * 340 px képarány javasolt.",
    "pages.productList.form.imageMobileLabel": "Mobil kép",
    "pages.productList.form.imageMobile.helperText": "600 * 300 px képarány javasolt.",
    "pages.productList.form.addProductsHeading": "Termékek hozzáadása",
    "pages.productList.form.modeLabel.manual": "Manuálisan",
    "pages.productList.form.modeLabel.auto": "Automatikusan",
    "pages.productList.form.addProductHint": "A termék listához hozzáadhat termékeket manuálisan egyesével vagy tömegesen.",
    "pages.productList.form.addProductButton": "Hozzáadás",
    "pages.productList.form.badgeHeading": "Plecsni",
    "pages.productList.form.addBadgeButton": "Plecsni hozzáadása",
    "pages.productList.form.groupByCategoryLabel": "Termékek megjelenítése főkategóriákba sorolva",
    "pages.productList.form.addProductDialog.description": "Jelölje ki az alábbi termék listából azokat a termékeket, melyeket össze szeretne kötni variánsként.",
    "pages.productList.form.filters.addFilterButton": "Szabály hozzáadása",
    "pages.productList.form.filters.typeLabel": "Szabály kiválasztása",
    "pages.productList.form.filters.operatorLabel": "Feltétel",
    "pages.productList.form.filters.valueLabel.product_param": "Mentesség neve",
    "pages.productList.form.filters.valueLabel.promotion": "Kedvezmény neve",
    "pages.productList.form.filters.valueLabel.product_category": "Kategória neve",
    "pages.productList.form.filters.valueLabel.brand": "Márka neve",
    "pages.productList.form.filters.valueLabel.created": "Időpont",
    "pages.productList.form.filters.valueLabel.rating": "Értékelés",
    "pages.productList.form.filters.valueLabel.price_type": "EVO ár típus alapján",
    "pages.productList.form.filters.valueLabel.clpe_promotion_type": "Kedvezményplecsni alapján",
    "pages.productList.form.filters.valueLabel.product_type": "Termék típus alapján",
    "pages.productList.form.filtersText":
        "A termék listákhoz szabályok megadásával is rendelhet termékeket. Ekkor a lista összetétele dinamikusan változik a megadott szabálynak megfelelően.",
    "pages.productList.form.reorderButtonLabel": "Sorrend szerkesztése",
    "pages.productList.form.reorderModal.title": "Sorrend szerkesztése",
    "pages.productList.form.reorderModal.confirmButtonLabel": "Rendben",

    "pages.badgeList.title": "Plecsnik",
    "pages.badgeList.addButton": "Új plecsni",
    "pages.badgeList.grid.column.id": "ID",
    "pages.badgeList.grid.column.name": "Plecsni neve",
    "pages.badgeList.grid.column.type": "Típus",
    "pages.badgeList.grid.column.created_at": "Létrehozás dátuma",
    "pages.badgeList.grid.column.is_visible": "Státusz",
    "pages.badgeList.grid.column.status.active": "Aktív",
    "pages.badgeList.grid.column.status.inactive": "Inaktív",

    "pages.badge.form.idLabel": "ID",
    "pages.badge.form.typeLabel": "Típus kiválasztása",
    "pages.badge.form.nameLabel": "Admin név",
    "pages.badge.form.infoLabel": "Plecsnin megjelenő név",
    "pages.badge.form.infoHelperText": "120 * 120 px képarány javasolt.",
    "pages.badge.form.productParamHint":
        "A mentességi információk plecsniket termékjellemzőkhöz lehet rendelni. A termékjellemző kiválasztása után az adott plecsni az összes terméknél megjelenik, melyre a kiválasztott jellemző igaz.",
    "pages.badge.form.productParamIdLabel": "Termékjellemző kiválasztása",
    "pages.badge.form.statusLabel": "Státusz a websiteon",
    "pages.badge.form.isActiveLabel": "Aktív",
    "pages.badge.form.imageLabel": "Ikon",

    "pages.badge.onCreateSubmit.succeed": "Plecsni létrehozása sikeres!",
    "pages.badge.onCreateSubmit.error": "Plecsni létrehozása sikertelen!",
    "pages.badge.createBreadcrumb": "Plecsnik",
    "pages.badge.new": "Új plecsni létrehozása",

    "pages.badge.onEditSubmit.succeed": "Plecsni módosítása sikeres!",
    "pages.badge.onEditSubmit.error": "Plecsni módosítása sikertelen!",
    "pages.badge.editBreadcrumb": "Plecsnik",
    "pages.badge.edit.deleteSucceed": "Plecsni törlése sikeres!",
    "pages.badge.edit.deleteError": "Plecsni törlése sikertelen!",
    "pages.badge.edit.deleteModal.title": "Plecsni törlése",
    "pages.badge.edit.deleteModal.description": "Biztosan törölni szeretné a plecsnit?",

    "pages.content.create.breadcrumb": "Cikk létrehozása",
    "pages.content.create.title": "Cikk létrehozása",
    "pages.content.create.succeed": "Cikk létrehozása sikeres!",

    "pages.content.edit.breadcrumb": "Cikk szerkesztése",
    "pages.content.save.succeed.schedule": "Sikeres időzítés!",
    "pages.content.save.succeed.removeSchedule": "Időzítés sikeresen megszüntetve!",
    "pages.content.save.succeed.update": "Sikeres frissítés!",
    "pages.content.save.succeed.draft": "Sikeres mentés!",
    "pages.content.save.succeed.publish": "Sikeres publikálás!",
    "pages.content.save.succeed.unPublish": "Publikálás sikeresen megszüntetve!",
    "pages.content.save.failed.schedule": "Sikertelen időzítés!",
    "pages.content.save.failed.removeSchedule": "Időzítés megszüntetése sikertelen!",
    "pages.content.save.failed.update": "Sikertelen frissítés!",
    "pages.content.save.failed.draft": "Sikertelen mentés!",
    "pages.content.save.failed.publish": "Sikertelen publikálás!",
    "pages.content.save.failed.unPublish": "Publikálás megszüntetése sikertelen!",

    "pages.content.form.button.schedule": "Időzítés",
    "pages.content.form.button.removeSchedule": "Időzítés megszüntetése",
    "pages.content.form.button.update": "Frissítés",
    "pages.content.form.button.draft": "Piszkozat mentése",
    "pages.content.form.button.publish": "Publikálás",
    "pages.content.form.button.unPublish": "Publikálás megszüntetése",

    "pages.content.form.content.heading": "Tartalom",
    "pages.content.form.title": "Cím",
    "pages.content.form.lead": "Lead",
    "pages.content.form.image": "Listakép",
    "pages.content.form.content": "Cikk tartalma",
    "pages.content.form.category": "Rovat",
    "pages.content.form.category.none": "- Adj meg egy rovatot! -",
    "pages.content.form.author": "Szerző",
    "pages.content.form.documents": "Kapcsolódó dokumentumok",
    "pages.content.form.publish.heading": "Publikálás adatai",
    "pages.content.form.activeFrom": "Megjelenés időpontja",
    "pages.content.form.activeUntil": "Lejárat időpontja",
    "pages.content.form.url": "URL",
    "pages.content.form.urlHelperText": "Kötelező mező, a cím mezőből generálja a rendszer, módosítható",
    "pages.content.form.id": "ID",
    "pages.content.form.ogTags": "OpenGraph tulajdonságok",
    "pages.content.form.ogTitle": "og:title",
    "pages.content.form.ogDescription": "og:description",
    "pages.content.form.ogKeywords": "og:keywords",
    "pages.content.form.ogImage": "og:image",
    "pages.content.form.isPublished": "Publikálva",
    "pages.content.form.lastPublished": "Utoljára publikálva",
    "pages.content.form.updatedAt": "Utolsó módosítás",
    "pages.content.form.attachDocumentFailed": "A '${document}' dokumentum csatolása sikertelen!",
    "pages.content.form.detachDocumentFailed": "A '${document}' dokumentum leválasztása sikertelen!",
    "pages.content.form.documentModal.title": "Dokumentumok hozzáadása",
    "pages.content.form.documentModal.add": "Hozzáadás",
    "pages.content.form.documentModal.description": "Válasszon az alábbi listából, vagy töltsön fel elemeket",
    "pages.content.form.documentModal.upload": "Feltöltés",
    "pages.content.edit.deleteModal.title": "Cikk törlése",
    "pages.content.edit.deleteModal.description": "Biztosan törölni szeretné a cikket?",
    "pages.content.edit.deleteSucceed": "Banner törlése sikeres!",
    "pages.content.edit.deleteFailed": "Banner törlése sikertelen!",

    "pages.contentList.breadcrumb": "Cikkek",
    "pages.contentList.add": "Új cikk",
    "pages.contentList.grid.column.id": "ID",
    "pages.contentList.grid.column.title": "Cím",
    "pages.contentList.grid.column.category": "Kategória",
    "pages.contentList.grid.column.updatedAt": "Módosítva",
    "pages.contentList.grid.column.status": "Publikálva",
    "pages.contentList.grid.column.status.draft": "Piszkozat",
    "pages.contentList.grid.column.status.scheduled": "Időzített",
    "pages.contentList.grid.column.status.published": "Publikált",
    "pages.contentList.title": "Cikkek",

    "pages.bannerList.title": "Bannerek",
    "pages.bannerList.addButton": "Új banner",
    "pages.bannerList.grid.column.id": "ID",
    "pages.bannerList.grid.column.name": "Banner neve",
    "pages.bannerList.grid.column.target_url": "Link",
    "pages.bannerList.grid.column.type": "Típus",
    "pages.bannerList.grid.column.active_from": "Megjelenés kezdete",
    "pages.bannerList.grid.column.active_to": "Megjelenés vége",
    "pages.bannerList.grid.column.is_active": "Státusz",
    "pages.banner.createBreadcrumb": "Bannerek",
    "pages.banner.new": "Új banner",
    "pages.banner.onCreateSubmit.succeed": "Banner létrehozása sikeres!",
    "pages.banner.onCreateSubmit.error": "Banner létrehozása sikertelen!",
    "pages.banner.onEditSubmit.succeed": "Banner szerkesztése sikeres!",
    "pages.banner.onEditSubmit.error": "Banner szerkesztése sikertelen!",
    "pages.banner.form.idLabel": "ID",
    "pages.banner.form.nameLabel": "Banner neve",
    "pages.banner.form.urlLabel": "URL",
    "pages.banner.form.urlHelperText": 'Pl. kategória esetében "/kategoria/haztartas" vagy termékszegmens esetében "webshopunk-kinalatabol"',
    "pages.banner.form.titleLabel": "Cím",
    "pages.banner.form.buttonTitleLabel": "Gomb neve",
    "pages.banner.form.imageLabel": "Kép",
    "pages.banner.form.imageHelperText": "Kötelező mező. 2280 * 480 px képarány javasolt.",
    "pages.banner.form.imageMobileLabel": "Mobilkép",
    "pages.banner.form.imageMobileHelperText": "Kötelező mező. 600 * 332 px képarány javasolt.",
    "pages.banner.form.targetUrlLabel": "Link",
    "pages.banner.form.targetUrlHelperText": 'Pl.: külső URL esetén "https://www.google.com", belső esetén "/termekszegmens/webshopunk-kinalatabol"',
    "pages.banner.form.statusLabel": "Státusz a websiteon",
    "pages.banner.form.isActiveLabel": "Aktív",
    "pages.banner.form.activeFromLabel": "Megjelenés kezdete",
    "pages.banner.form.activeToLabel": "Megjelenés vége",
    "pages.banner.form.targetPopupLabel": "Megnyitás új ablakban",
    "pages.banner.editBreadcrumb": "Bannerek",
    "pages.banner.form.imageAlignLabel": "Kép igazítása",
    "pages.banner.form.sliderTitleLabel": "Slider cím",
    "pages.banner.form.productIdLabel": "Termék ID",
    "pages.banner.form.positionLabel": "Pozíció a listában",
    "pages.banner.edit.deleteModal.title": "Banner törlése",
    "pages.banner.edit.deleteModal.description": "Biztosan törölni szeretné a bannert?",
    "pages.banner.edit.deleteSucceed": "Banner törlése sikeres!",
    "pages.banner.edit.deleteError": "Biztosan törlése sikertelen!",

    "pages.dashboard.welcome": "Üdv, {name}!",

    "pages.departmentList.title": "Szervezeti egységek",
    "pages.departmentList.grid.column.id": "ID",
    "pages.departmentList.grid.column.name": "Megnevezés",
    "pages.departmentList.grid.column.created_at": "Létrehozás dátuma",
    "pages.departmentList.grid.column.updated_at": "Módosítás dátuma",
    "pages.departmentList.add": "Új szervezeti egység",
    "pages.departmentList.breadcrumb": "Szervezeti egységek",
    "pages.department.edit.breadcrumb": "Szervezeti egység szerkesztése",
    "pages.department.edit.succeed": "Szervezeti egység szerkesztése sikeres!",
    "pages.department.edit.failed": "Szervezeti egység szeresztése sikertelen!",
    "pages.department.create.succeed": "Szervezeti egység létrehozása sikeres!",
    "pages.department.create.failed": "Szervezeti egység létrehozása sikertelen!",
    "pages.department.create.breadcrumb": "Új szervezeti egység létrehozása",
    "pages.department.create.title": "Új szervezeti egység létrehozása",
    "pages.department.form.title.label": "Név",
    "pages.department.form.is_grouped": "Az állások csoportosítva jelenjenek meg",
    "pages.department.form.is_filter_enabled": "Város és üzleti terület szűrés megjelenjen-e, ha csoportosított",
    "pages.department.form.is_map_enabled": "Térképes nézet megjelenjen-e, ha csoportosított",
    "pages.department.form.divisions.label": "Üzleti területek",
    "pages.department.form.divisions.grid.column.id": "ID",
    "pages.department.form.divisions.grid.column.name": "Név",
    "pages.department.form.benefits.label": "Juttatások",
    "pages.department.edit.deleteModal.title": "Szervezeti egység törlése",
    "pages.department.edit.deleteModal.description": "Biztosan törlöd a juttatást?",

    "pages.divisionList.title": "Üzleti területek",
    "pages.divisionList.grid.column.id": "ID",
    "pages.divisionList.grid.column.name": "Megnevezés",
    "pages.divisionList.grid.column.department": "Szervezeti egység",
    "pages.divisionList.grid.column.created_at": "Létrehozás dátuma",
    "pages.divisionList.grid.column.updated_at": "Módosítás dátuma",
    "pages.divisionList.add": "Új üzleti terület",
    "pages.divisionList.breadcrumb": "Üzleti területek",
    "pages.division.edit.breadcrumb": "Üzleti terület szerkesztése",
    "pages.division.edit.succeed": "Üzleti terület szerkesztése sikeres!",
    "pages.division.edit.failed": "Üzleti terület szeresztése sikertelen!",
    "pages.division.create.succeed": "Üzleti terület létrehozása sikeres!",
    "pages.division.create.failed": "Üzleti terület létrehozása sikertelen!",
    "pages.division.create.breadcrumb": "Új üzleti terület létrehozása",
    "pages.division.create.title": "Új üzleti terület létrehozása",
    "pages.division.form.title.label": "Név",
    "pages.division.form.description.label": "Leírás",
    "pages.division.form.department.label": "Szervezeti egység",
    "pages.division.form.is_virtual": "Virtuális üzleti terület (kizárólagos megjelenítés listaoldalon)",
    "pages.division.form.quote.label": "Idézet",
    "pages.division.form.leaders.label": "Vezetők",
    "pages.division.form.benefits.label": "Juttatások",
    "pages.division.form.benefit.add.title": "Juttatások",
    "pages.division.form.benefit.add.description": "Jelölje ki az alábbi termék listából a hozzáadandó juttatásokat. A juttatások hozzáadása tömegesen is végrehajtható.",
    "pages.division.form.benefit.add.selected": "{count} kijelölt elem",
    "pages.division.form.benefits.grid.column.id": "ID",
    "pages.division.form.benefits.grid.column.title": "Név",
    "pages.division.form.benefits.grid.column.functions": "Funkciók",
    "pages.division.edit.deleteModal.title": "Üzleti terület törlése",
    "pages.division.edit.deleteModal.description": "Biztosan törlöd ezt az üzleti területet?",

    "pages.documentList.add": "Dokumentum feltöltése",
    "pages.documentList.grid.column.id": "ID",
    "pages.documentList.grid.column.file_name": "Megnevezés",
    "pages.documentList.grid.column.url": "URL",
    "pages.documentList.title": "Dokumentumok",
    "pages.documentList.saveSucceed": "Dokumentum feltöltése sikeres!",
    "pages.documentList.copyToClipboard": "Dokumentum url vágólapra másolása",
    "pages.documentList.copyToClipboardSucceed": "Dokumentum url vágólapra másolása sikeres!",

    "pages.emailActivation.description": "A fiók aktiválásához adj meg egy jelszót, melyet használni szeretnél a jövőben!",
    "pages.emailActivation.failed": "Fiók aktiválása sikertelen!",
    "pages.emailActivation.form.info": "A jelszónak legalább 9 karakterből kell állnia, tartalmaznia kell kis- és nagybetűket, valamint számot és speciális karaktert.",
    "pages.emailActivation.form.password.label": "Jelszó",
    "pages.emailActivation.form.passwordConfirmation.label": "Jelszó megerősítése",
    "pages.emailActivation.form.submit": "Fiók aktiválása",
    "pages.emailActivation.succeed": "Sikeres fiók aktiválás!",
    "pages.emailActivation.title": "Fiók aktiválása",

    "pages.forgotPassword.description": "Kérlek add meg e-mail címed a jelszóvisszaállítás hivatkozásának elküldéséhez.",
    "pages.forgotPassword.form.email.label": "E-mail cím",
    "pages.forgotPassword.form.login": "Bejelentkezés",
    "pages.forgotPassword.form.submit": "Elküldés",
    "pages.forgotPassword.reset.failed": "A jelszóvisszaállító e-mail kiküldése sikertelen!",
    "pages.forgotPassword.reset.succeed": "A jelszóvisszaállító e-mail kiküldése sikeres!",
    "pages.forgotPassword.title": "Jelszó visszaállítás",

    "pages.galleryList.add": "Új Galéria",
    "pages.galleryList.grid.column.id": "ID",
    "pages.galleryList.grid.column.title": "Cím",
    "pages.galleryList.grid.column.imageCount": "Elemek",
    "pages.galleryList.grid.column.handle": "Hivatkozási név",
    "pages.galleryList.grid.column.updatedAt": "Módosítva",
    "pages.galleryList.title": "Képgalériák",
    "pages.galleryList.breadcrumb": "Galériák",

    "pages.gallery.create.breadcrumb": "Galéria létrehozása",
    "pages.gallery.create.title": "Galéria létrehozása",
    "pages.gallery.create.succeed": "Galéria létrehozása sikeres!",
    "pages.gallery.create.failed": "Galéria létrehozása sikertelen!",

    "pages.gallery.edit.breadcrumb": "Galéria szerkesztése",
    "pages.gallery.edit.succeed": "Galéria szerkesztése sikeres!",
    "pages.gallery.edit.failed": "Galéria szerkesztése sikertelen!",
    "pages.gallery.edit.deleteModal.title": "Galéria törlése",
    "pages.gallery.edit.deleteModal.description": "Biztosan törölni szeretné a galériát?",
    "pages.gallery.edit.deleteSucceed": "Galéria törlése sikeres!",
    "pages.gallery.edit.deleteFailed": "Galéria törlése sikertelen!",

    "pages.gallery.form.addFromMediaLibrary": "hozzáadás a médiatárból",
    "pages.gallery.form.tab.content": "Tartalom",
    "pages.gallery.form.tab.sort": "Sorrend",
    "pages.gallery.form.title": "Galéria címe",
    "pages.gallery.form.handle": "Hivatkozási név",
    "pages.gallery.form.image.caption": "Képaláírás",
    "pages.gallery.form.image.credit": "Kredit",
    "pages.gallery.form.sort.emptyList": "Nincs megjeleníthető elem!",

    "pages.login.description": "Kérlek add meg e-mail címed vagy azonosítódat és jelszavad",
    "pages.login.form.login.label": "E-mail cím vagy azonosító",
    "pages.login.form.forgotPassword": "Jelszó visszaállítás",
    "pages.login.form.password.label": "Jelszó",
    "pages.login.form.submit": "Bejelentkezés",
    "pages.login.title": "Bejelentkezés",

    "pages.myProfile.changePasswordModal.description": "Kérlek add meg a jelenlegi és az új jelszót, melyet használni szeretnél a jövőben!",
    "pages.myProfile.changePasswordModal.form.password.label": "Jelenlegi jelszó",
    "pages.myProfile.changePasswordModal.form.passwordNew.label": "Új jelszó",
    "pages.myProfile.changePasswordModal.form.passwordNewConfirmation.label": "Új jelszó megerősítése",
    "pages.myProfile.changePasswordModal.info": "A jelszónak legalább 9 karakterből kell állnia, tartalmaznia kell kis- és nagybetűket, valamint számot és speciális karaktert.",
    "pages.myProfile.changePasswordModal.saveFailed": "Jelszó módosítása sikertelen!",
    "pages.myProfile.changePasswordModal.saveSucceed": "Jelszó módosítása sikeres!",
    "pages.myProfile.changePasswordModal.submit": "Jelszó megváltoztatása",
    "pages.myProfile.changePasswordModal.title": "Jelszó módosítás",
    "pages.myProfile.form.changePassword": "Jelszó módosítása",
    "pages.myProfile.form.email.label": "E-mail cím",
    "pages.myProfile.form.name.label": "Név",
    "pages.myProfile.form.phone.label": "Telefonszám",
    "pages.myProfile.form.permissions": "Jogosultságok",
    "pages.myProfile.form.title": "Fiók adatok",
    "pages.myProfile.saveFailed": "Profil módosítása sikertelen!",
    "pages.myProfile.saveSucceed": "Profil módosítása sikeres!",

    "pages.personList.title": "Személyek",
    "pages.personList.grid.column.id": "ID",
    "pages.personList.grid.column.name": "Név",
    "pages.personList.grid.column.position": "Pozíció",
    "pages.personList.grid.column.created_at": "Létrehozás dátuma",
    "pages.personList.grid.column.updated_at": "Módosítás dátuma",
    "pages.personList.add": "Új személy",
    "pages.personList.breadcrumb": "Személyek",
    "pages.person.edit.breadcrumb": "Személy szerkesztése",
    "pages.person.edit.succeed": "Személy szerkesztése sikeres!",
    "pages.person.edit.failed": "Személy szeresztése sikertelen!",
    "pages.person.edit.deleteModal.title": "Személy törlése",
    "pages.person.edit.deleteModal.description": "Biztosan törlöd?",
    "pages.person.create.succeed": "Személy létrehozása sikeres!",
    "pages.person.create.failed": "Személy létrehozása sikertelen!",
    "pages.person.create.breadcrumb": "Új személy létrehozása",
    "pages.person.create.title": "Új személy létrehozása",
    "pages.person.form.name.label": "Név",
    "pages.person.form.position.label": "Pozíció",
    "pages.person.form.image.label": "Kép",

    "pages.redirectionList.title": "URL átirányítások",
    "pages.redirectionList.breadcrumb": "URL átirányítások",
    "pages.redirectionList.add": "Új URL átirányítás",
    "pages.redirectionList.grid.column.id": "ID",
    "pages.redirectionList.grid.column.from": "Forrás URL",
    "pages.redirectionList.grid.column.to": "Cél URL",
    "pages.redirectionList.grid.column.created_at": "Létrehozás dátuma",
    "pages.redirectionList.grid.column.updated_at": "Módosítás dátuma",
    "pages.redirectionList.grid.column.status": "Státusz",
    "pages.redirectionList.grid.column.status.active": "Aktív",
    "pages.redirectionList.grid.column.status.inactive": "Inaktív",
    "pages.redirectionList.grid.column.from_site_id": "Forrás oldal",
    "pages.redirectionList.grid.column.to_site_id": "Cél oldal",

    "pages.redirection.create.breadcrumb": "URL átirányítás létrehozása",
    "pages.redirection.create.title": "URL átirányítás létrehozása",
    "pages.redirection.create.succeed": "URL átirányítás létrehozása sikeres!",
    "pages.redirection.create.failed": "URL átirányítás létrehozása sikertelen!",

    "pages.redirection.edit.breadcrumb": "URL átirányítás szerkesztése",
    "pages.redirection.edit.title": "URL átirányítás szerkesztése",
    "pages.redirection.edit.succeed": "URL átirányítás mentése sikeres!",
    "pages.redirection.edit.failed": "URL átirányítás mentése sikertelen!",
    "pages.redirection.edit.deleteModal.title": "URL átirányítás törlése",
    "pages.redirection.edit.deleteModal.description": "Biztosan törlöd?",

    "pages.redirection.form.from.label": "Forrás URL",
    "pages.redirection.form.is_regex.label": "Forrás URL reguláris kifejezést tartalmaz",
    "pages.redirection.form.is_regex": "Igen",
    "pages.redirection.form.to.label": "Cél URL",
    "pages.redirection.form.is_permanent.label": "Átirányítás típusa",
    "pages.redirection.form.is_permanent.true": "Végleges (HTTP 301 - “Moved Permanently”)",
    "pages.redirection.form.is_permanent.false": "Ideiglenes (HTTP 302 - “Moved Temporarily)",
    "pages.redirection.form.active_from.label": "Érvényesség kezdete",
    "pages.redirection.form.active_to.label": "Érvényesség vége",

    "pages.redirection.form.fromSiteIdLabel": "Forrás oldal",
    "pages.redirection.form.toSiteIdLabel": "Cél oldal",
    "pages.redirection.form.forward.label": "Csak a hoszt cseréje",
    "pages.redirection.form.forward": "Igen",

    "pages.resetPassword.description": "Adj meg egy jelszót, melyet használni szeretnél a jövőben!",
    "pages.resetPassword.form.info": "A jelszónak legalább 9 karakterből kell állnia, tartalmaznia kell kis- és nagybetűket, valamint számot és speciális karaktert.",
    "pages.resetPassword.form.password.label": "Jelszó",
    "pages.resetPassword.form.passwordConfirmation.label": "Jelszó megerősítése",
    "pages.resetPassword.form.submit": "Jelszó beállítása",
    "pages.resetPassword.succeed": "Jelszó beállítása sikeres!",
    "pages.resetPassword.title": "Jelszó beállítása",

    "pages.userList.title": "Regisztrált felhasználók",
    "pages.userList.grid.column.id": "ID",
    "pages.userList.grid.column.name": "Név",
    "pages.userList.grid.column.email": "E-mail cím",
    "pages.userList.grid.column.card_number": "Rossmann+ kártyaszám",
    "pages.userList.grid.column.phone_number_full": "Telefonszám",
    "pages.userList.grid.column.zip_code": "Ir.szám",
    "pages.userList.grid.column.created_at": "Regisztráció időpontja",
    "pages.userList.grid.column.is_active": "Státusz",
    "pages.user.edit.deleteSucceed": "Regisztrált felhasználó törlése sikeres!",
    "pages.user.edit.deleteError": "Regisztrált felhasználó törlése sikertelen!",
    "pages.user.edit.deleteModal.title": "Regisztrált felhasználó törlése",
    "pages.user.edit.deleteModal.description": "Biztosan törölni szeretné a regisztrált felhasználót?",
    "pages.user.edit.adminLoginAsUserButton": "SHOP BELÉPÉS",
    "pages.user.edit.loginAsUserSucceed": "Shop belépés sikeres!",
    "pages.user.edit.loginAsUserError": "Shop belépés sikertelen!",
    "pages.user.onEditSubmit.succeed": "Regisztrált felhasználó szerkesztése sikeres!",
    "pages.user.onEditSubmit.error": "Regisztrált felhasználó szerkesztése sikertelen!",
    "pages.user.editBreadcrumb": "Regisztrált felhasználók",
    "pages.user.form.personalDataSection": "Személyes adatok",
    "pages.user.form.lastNameLabel": "Vezetéknév",
    "pages.user.form.firstNameLabel": "Keresztnév",
    "pages.user.form.emailLabel": "E-mail cím",
    "pages.user.form.rossmannPlusSection": "Rossmann+",
    "pages.user.form.rossmannPlusReplaceCardNumber": "Kártyaszám",
    "pages.user.form.rossmannPlusReplaceCardToVirtual": "Csere virtuális kártyára",
    "pages.user.form.replaceCardToVirtualModal.title": "Csere virtuális kártyára",
    "pages.user.form.replaceCardToVirtualModal.description": "A művelet nem vonható vissza. Biztosan cseréli?",
    "pages.user.form.rossmannPlusReplaceCardToPlastic": "Csere plasztik kártyára",
    "pages.user.form.replaceCardToPlasticModal.title": "Csere plasztik kártyára",
    "pages.user.form.replaceCardToVirtualSuccess": "A kártya cseréje sikeres!",
    "pages.user.form.replaceCardToVirtualError": "A kártya cseréje sikertelen!",
    "pages.user.form.replaceCardToPlasticSuccess": "A kártya cseréje sikeres!",
    "pages.user.form.replaceCardToPlasticError": "A kártya cseréje sikertelen!",
    "pages.user.form.replaceCardToPlasticModal.submit": "Kártya cseréje",
    "pages.user.form.replaceCardToPlasticModal.replacementCardNumberLabel": "Csere kártya szám",
    "pages.user.form.accountSection": "Fiók adatok",
    "pages.user.form.idLabel": "User ID",
    "pages.user.form.rossmannPlusCardLabel.virtual": "Digitális kártya",
    "pages.user.form.rossmannPlusCardLabel.plastic": "Plasztik kártya",
    "pages.user.form.phoneNumberLabel": "Telefonszám",
    "pages.user.form.createdAtLabel": "Regisztráció időpontja",
    "pages.user.form.lastLogin": "Utolsó belépés időpontja",
    "pages.user.form.bankTransferEnabledLabel": "Átutalásos fizetési mód engedélyezése",
    "pages.user.form.codDisabledLabel": "Utánvét engedélyezése",
    "pages.user.form.newsletterLabel": "Hírlevél feliratkozás",
    "pages.user.form.newsletterCheckboxLabel": "Feliratkozva",
    "pages.user.form.isActiveLabel": "Felhasználó státusza",
    "pages.user.form.isActiveCheckboxLabel": "Aktív",
    "pages.user.form.profileTabLabel": "PROFIL ADATOK",
    "pages.user.form.ordersTabLabel": "RENDELÉSEK",
    "pages.user.form.orderNumberLabel": "Rendelések száma",
    "pages.user.form.orderGrandTotalLabel": "Rendelések összértéke",
    "pages.user.form.ordersDataGrid.column.id": "ID",
    "pages.user.form.ordersDataGrid.column.created_at": "Rendelés dátuma",
    "pages.user.form.ordersDataGrid.column.grand_total": "Összeg",
    "pages.user.form.ordersDataGrid.column.shipping_method_name": "Áruátvétel módja",
    "pages.user.form.ordersDataGrid.column.payment_method_name": "Fizetés módja",
    "pages.user.form.ordersDataGrid.column.payment_status_title": "Fizetési státusz",
    "pages.user.form.ordersDataGrid.select.shippingMethod.mpl_delivery": "MPL kiszállítás",
    "pages.user.form.ordersDataGrid.select.shippingMethod.mpl_pickup": "MPL elvitel",
    "pages.user.form.ordersDataGrid.select.shippingMethod.rossmann_delivery": "Rossmann kiszállítás",
    "pages.user.form.ordersDataGrid.select.shippingMethod.rossmann_shop": "Rossmann üzlet",
    "pages.user.form.ordersDataGrid.select.paymentMethod.bank_transfer": "Banki átutalás",
    "pages.user.form.ordersDataGrid.select.paymentMethod.barion": "Barion",
    "pages.user.form.ordersDataGrid.select.paymentMethod.cod": "Utánvét",
    "pages.user.form.ordersDataGrid.select.paymentMethod.simplepay": "SimplePay",

    "pages.successStoryList.title": "Sikertörténetek",
    "pages.successStoryList.grid.column.id": "ID",
    "pages.successStoryList.grid.column.person": "Személy",
    "pages.successStoryList.grid.column.division": "Üzleti terület",
    "pages.successStoryList.grid.column.created_at": "Létrehozás dátuma",
    "pages.successStoryList.grid.column.updated_at": "Módosítás dátuma",
    "pages.successStoryList.add": "Új sikertörténet",
    "pages.successStoryList.breadcrumb": "Sikertörténetek",
    "pages.successStory.edit.breadcrumb": "Sikertörténet szerkesztése",
    "pages.successStory.edit.succeed": "Sikertörténet szerkesztése sikeres!",
    "pages.successStory.edit.failed": "Sikertörténet szeresztése sikertelen!",
    "pages.successStory.edit.deleteModal.title": "Sikertörténet törlése",
    "pages.successStory.edit.deleteModal.description": "Biztosan törlöd?",
    "pages.successStory.create.succeed": "Sikertörténet létrehozása sikeres!",
    "pages.successStory.create.failed": "Sikertörténet létrehozása sikertelen!",
    "pages.successStory.create.breadcrumb": "Új sikertörténet létrehozása",
    "pages.successStory.create.title": "Új sikertörténet létrehozása",
    "pages.successStory.form.person.label": "Személy",
    "pages.successStory.form.content.label": "Tartalom",
    "pages.successStory.form.division.label": "Üzleti terület",

    "pages.mailTemplateList.title": "Tranzakciós levelek",
    "pages.mailTemplateList.grid.column.id": "ID",
    "pages.mailTemplateList.grid.column.subject": "Tárgy",
    "pages.mailTemplateList.grid.column.is_active": "Státusz",
    "pages.mailTemplate.onEditSubmit.succeed": "Tranzakciós levél szerkesztése sikeres!",
    "pages.mailTemplate.onEditSubmit.error": "Tranzakciós levél szerkesztése sikertelen!",
    "pages.mailTemplate.sendTestMail.succeed": "Tranzakciós levél küldése sikeres!",
    "pages.mailTemplate.sendTestMail.error": "Tranzakciós levél küldése sikertelen!",
    "pages.mailTemplate.editBreadcrumb": "Tranzakciós levelek",
    "pages.mailTemplate.form.subjectLabel": "Tárgy",
    "pages.mailTemplate.form.contentLabel": "Tartalom",
    "pages.mailTemplate.form.descriptionLabel": "Leírás",
    "pages.mailTemplate.form.statusLabel": "Státusz",
    "pages.mailTemplate.form.isActiveLabel": "Aktív",
    "pages.mailTemplate.form.previewButton": "Előnézet",
    "pages.mailTemplate.form.testMailTitle": "Teszt e-mail küldése",
    "pages.mailTemplate.form.testMailTitleAll": "Összes teszt e-mail küldése",
    "pages.mailTemplate.previewError": "Előnézet betöltése sikertelen!",
    "pages.pageList.title": "Oldalszerkesztő",
    "pages.pageList.grid.column.id": "ID",
    "pages.pageList.grid.column.name": "Oldal neve",
    "pages.pageList.grid.column.url": "URL",
    "pages.pageList.grid.column.active_from": "Megjelenés kezdete",
    "pages.pageList.grid.column.active_to": "Megjelenés vége",
    "pages.pageList.grid.column.updated_at": "Utolsó módosítás",
    "pages.pageList.grid.column.is_visible": "Státusz",
    "pages.pageList.addButton": "Új oldal",

    "pages.systemSettingList.grid.column.id": "Név",
    "pages.systemSettingList.grid.column.value": "Érték",
    "pages.systemSettingList.grid.column.description": "Leírás",
    "pages.systemSettingList.grid.column.updated_by": "Módosította",
    "pages.systemSettingList.grid.column.updated_at": "Módosítás dátuma",
    "pages.systemSettingList.editModal.valueLabel": "Érték",
    "pages.systemSettingList.editModal.descriptionLabel": "Leírás",
    "pages.systemSettingList.editModal.dateTimeHelper": "Javasolt formátum: 'éééé-hh-nn óó:pp:mp'",
    "pages.systemSettingList.editModal.jsonError": "Hibás JSON formátum!",
    "pages.systemSettingList.onEditSubmit.succeed": "Rendszerparaméter szerkesztése sikeres!",
    "pages.systemSettingList.onEditSubmit.error": "Rendszerparaméter szerkesztése sikertelen!",

    "pages.page.onCreateSubmit.succeed": "Oldal létrehozása sikeres!",
    "pages.page.onCreateSubmit.error": "Oldal létrehozása sikertelen!",
    "pages.page.createBreadcrumb": "Oldalszerkesztő",
    "pages.page.new": "Új oldal",
    "pages.page.onEditSubmit.succeed": "Oldal szerkesztése sikeres!",
    "pages.page.onEditSubmit.error": "Oldal szerkesztése sikertelen!",
    "pages.page.editBreadcrumb": "Oldalszerkesztő",
    "pages.page.edit.deleteModal.title": "Oldal törlése",
    "pages.page.edit.deleteModal.description": "Biztosan törölni szeretné az oldalt?",
    "pages.page.form.idLabel": "ID",
    "pages.page.form.nameLabel": "Oldal neve (admin)",
    "pages.page.form.titleLabel": "Oldal neve (webshop)",
    "pages.page.form.urlLabel": "Oldal címe",
    "pages.page.form.statusLabel": "Státusz a websiteon",
    "pages.page.form.isActiveLabel": "Aktív",
    "pages.page.form.isHomeboxLabel": '"Miért a Rossmann?" blokk',
    "pages.page.form.isNewsletterLabel": "Hírlevél feliratkozás blokk",
    "pages.page.form.activeFromLabel": "Megjelenés kezdete",
    "pages.page.form.activeToLabel": "Megjelenés vége",
    "pages.page.form.addBlockButton": "Új blokk",
    "pages.page.form.bannerBlock.layoutLabel": "Layout kiválasztása",
    "pages.page.form.bannerBlock.titleLabel": "Blokk címe",
    "pages.page.form.bannerBlock.title": "Banner blokk",
    "pages.page.form.addBannerButton": "Banner",
    "pages.page.form.productListBlock.title": "Termékszegmens blokk",
    "pages.page.form.addProductListButton": "Szegmens",
    "pages.page.form.productListBlock.titleLabel": "Blokk címe",
    "pages.page.form.productListBlock.productLimitLabel": "Megjelenített termékek száma",
    "pages.page.form.productListBlock.isScrollableLabel": "Görgethető",
    "pages.page.form.productListBlock.productSortByLabel": "Termékek rendezése",
    "pages.page.form.productListBlock.noSegmentAdded": "Termékszegmens hozzáadása kötelező.",
    "pages.page.form.productListBlock.isFilterableLabel": "Szűrhető",
    "pages.page.form.htmlBlock.title": "Szöveg blokk",
    "pages.page.form.isHeaderLabel": "Fejléc megjelenítése",
    "pages.page.form.isFooterLabel": "Lábléc megjelenítése",
    "pages.page.form.siteIdLabel": "Oldal",
    "pages.page.form.newsletterBlock.title": "Hírlevél feliratkozás blokk",
    "pages.page.form.brandListBlock.title": "Márka lista blokk",
    "pages.page.form.recommendedProductsBlock.title": "Neked javasoljuk blokk",
    "pages.page.form.homeboxBlock.title": "'Miért a Rossmann?' blokk",
    "pages.page.form.tooManyFilterables": "Egy oldal csak egy szűrhető termékszegmens blokkot tartalmazhat!",
    "pages.page.edit.deleteSucceed": "Oldal törlése sikeres!",
    "pages.page.edit.deleteFailed": "Dokumentum törlése sikertelen!",
    "pages.shippingMethodList.title": "Szállítási módok",
    "pages.shippingMethod.onEditSubmit.succeed": "Szállítási mód szerkesztése sikeres!",
    "pages.shippingMethod.onEditSubmit.error": "Szállítási mód szerkesztése sikertelen!",
    "pages.shippingMethod.editBreadcrumb": "Szállítási módok",
    "pages.shippingMethodList.fulfillment.label": "Flagelés",

    "pages.shippingMethod.form.nameLabel": "Megnevezés",
    "pages.shippingMethod.form.leadLabel": "Leírás",
    "pages.shippingMethod.form.descriptionLabel": "Hosszú leírás",
    "pages.shippingMethod.form.weightMinLabel": "Kosárlimit min (kg)",
    "pages.shippingMethod.form.weightMaxLabel": "Kosárlimit max (kg)",
    "pages.shippingMethod.form.statusLabel": "Szállítási mód státusza",
    "pages.shippingMethod.form.appearanceLabel": "Szállítási mód megjelenésének korlátozása",
    "pages.shippingMethod.form.isActiveLabel": "Aktív",
    "pages.shippingMethod.form.isAppearanceActiveLabel": "Korlátozás aktiválása",
    "pages.shippingMethod.form.priceLabel": "Bruttó összeg (Ft)",
    "pages.shippingMethod.form.orderMaxLabel": "Maximális rendelésszám naponta",
    "pages.shippingMethod.form.mplTimeWindow.cartLimitLabel": "Minimum kosár összeg (Ft)",
    "pages.shippingMethod.form.mplTimeWindow.costLabel": "Vásárlóra hárított ktg. (Ft)",
    "pages.shippingMethod.form.mplTimeWindow.addWindowButton": "Sáv hozzáadása",
    "pages.shippingMethod.form.mplTimeWindow.intervalHour": "{hour} órás időablak",
    "pages.shippingMethod.form.noTimeWindow": "Időablak nélkül",
    "pages.shippingMethod.form.costs": "Költségsávok",
    "pages.shippingMethod.form.costs.terminal": "Költségsávok (Csomagautomata)",
    "pages.shippingMethod.form.cartLimitMustHaveZero": "Legalább egy minimum összegnek 0-val kell kezdődnie!",
    "pages.shippingMethod.form.vipLevels": "VIP szintek",
    "pages.shippingMethod.form.vipLevels.addNew": "Korlátozás hozzáadása",
    "pages.shippingMethod.form.vipLevelHint": "Legalább egy VIP szint választása kötelező",
    "pages.shippingMethod.form.cartValueMinLabel": "Minimum értékhatár",
    "pages.shippingMethod.form.timeLimitNextDayDepartureLabel": "Kiszállítási időkorlát (óra)",
    "pages.shippingMethod.form.timeLimitNextDayDepartureHint": "Az időhatár, ameddig a következő szállítással kerülhetnek ki a boltokba a rendelések",

    "pages.shippingMethod.form.deliveryInformationLabel": "Megjelenítendő információ",
    "pages.shippingMethod.form.deliveryInformationHelperText": "Kötelező mező. A termékadatlapon és a checkoutban jelenik meg.",

    "pages.shippingMethod.form.active_from.label": "Érvényesség kezdete",
    "pages.shippingMethod.form.active_to.label": "Érvényesség vége",

    "pages.shippingMethod.form.deliveryInformations.addNew": "Szállítási idő hozzáadása",

    "pages.fulfillmentList.title": "DHL sávok",
    "pages.fulfillmentList.addButton": "Új fulfillment sáv",
    "pages.fulfillmentList.grid.column.id": "ID",
    "pages.fulfillmentList.grid.column.date": "Dátum",
    "pages.fulfillmentList.grid.column.limit_rossmann_a2": "Napi limit (Saját)",
    "pages.fulfillmentList.grid.column.limit_dhl": "Napi limit (DHL)",
    "pages.fulfillmentList.grid.column.count_rossmann_a2": "Ma megjelölt rendelések (Saját)",
    "pages.fulfillmentList.grid.column.count_dhl": "Ma megjelölt rendelések (DHL)",
    "pages.fulfillmentList.grid.column.timewindow_allowed": "Időablak",
    "pages.fulfillmentList.grid.column.shipping_methods": "Szállítási módok",
    "pages.fulfillmentList.grid.column.vip_levels": "Rossmann+ VIP szintek",
    "pages.fulfillmentList.grid.column.created_at": "Létrehozás dátuma",
    "pages.fulfillmentList.grid.column.updated_at": "Módosítás dátuma",
    "pages.fulfillmentList.grid.column.active_from": "Érvényesség kezdete",
    "pages.fulfillmentList.grid.column.active_to": "Érvényesség vége",
    "pages.fulfillmentList.grid.column.date_reset_next": "Következő nullázó időpont",
    "pages.fulfillmentList.grid.column.is_active": "Aktív",

    "pages.fulfillment.onCreateSubmit.error": "DHL sáv létrehozása sikertelen!",
    "pages.fulfillment.onCreateSubmit.succeed": "DHL sáv létrehozása sikeres!",

    "pages.fulfillment.onEditSubmit.error": "DHL sáv módosítása sikertelen!",
    "pages.fulfillment.onEditSubmit.succeed": "DHL sáv módosítása sikeres!",

    "pages.fulfillment.new": "Új fulfillment sáv",
    "pages.fulfillment.editTitle": "Fulfillment sáv: {id}",
    "pages.fulfillment.form.active_from": "Érvényesség kezdete",
    "pages.fulfillment.form.active_to": "Érvényesség vége",
    "pages.fulfillment.form.limit_rossmann_a2": "Napi limit (Saját)",
    "pages.fulfillment.form.notification_threshold.label": "E-mail értesítés gyakorisága",
    "pages.fulfillment.form.notification_threshold.hint": "A flagelések száma, ami után e-mailes értesítőt küldünk",
    "pages.fulfillment.form.limit_dhl": "Napi limit (DHL)",
    "pages.fulfillment.form.shipping_methods": "Szállítási módok",
    "pages.fulfillment.form.timewindow_allowed": "Időablak engedélyezve",
    "pages.fulfillment.form.vip_levels": "Rossmann+ VIP szintek",
    "pages.fulfillment.form.is_active": "Aktív",
    "pages.fulfillment.form.quantity_max": "Maximum termék a rendelésben (db)",
    "pages.fulfillment.form.package_type_max": "Maximum doboz méret",
    "pages.fulfillment.form.product_id_a2_force": "Kényszerített A2 flagelés",
    "pages.fulfillment.form.product_id_a2_exclude": "A2 flagelés kizárása",
    "pages.fulfillment.form.product_id_dhl_force": "Kényszerített DHL flagelés",
    "pages.fulfillment.form.product_id_dhl_exclude": "DHL flagelés kizárása",
    "pages.fulfillment.form.addProductDialog.description": "Jelölje ki az alábbi termék listából azokat a termékeket, melyeket hozzá szeretne adni a listához.",

    "pages.fulfillment.edit.deleteModal.title": "DHL sáv törlése",
    "pages.fulfillment.edit.deleteModal.description": "Biztosan törölni szeretné a DHL sávot?",

    "pages.fulfillment.edit.deleteSucceed": "DHL sáv törlése sikeres!",
    "pages.fulfillment.edit.deleteError": "DHL sáv törlése sikertelen!",

    "pages.paymentMethodList.title": "Fizetési módok",
    "pages.paymentMethod.onEditSubmit.succeed": "Fizetési mód szerkesztése sikeres!",
    "pages.paymentMethod.onEditSubmit.error": "Fizetési mód szerkesztése sikertelen!",

    "pages.paymentMethod.form.nameLabel": "Megnevezés",
    "pages.paymentMethod.form.descriptionLabel": "Leírás",
    "pages.paymentMethod.form.priceLabel": "Bruttó összeg (Ft)",
    "pages.paymentMethod.form.statusLabel": "Fizetési mód státusza",
    "pages.paymentMethod.form.cartValueMaxLabel": "Utánvétes értékhatár (Ft)",
    "pages.paymentMethod.form.isActiveLabel": "Aktív",

    "pages.orderList.grid.column.increment_id": "ID",
    "pages.orderList.grid.column.created_at": "Megrendelés időpontja",
    "pages.orderList.grid.column.time_window_date": "Választott időablak",
    "pages.orderList.grid.column.delivery_day_expected": "Várható bolti kiszállítás",
    "pages.orderList.grid.column.user_fullname": "Felhasználó",
    "pages.orderList.grid.column.user_email": "E-mail cím",
    "pages.orderList.grid.column.grand_total": "Összeg",
    "pages.orderList.grid.column.shipping_method_name": "Áruátvétel módja",
    "pages.orderList.grid.column.time_window_interval": "Időablak",
    "pages.orderList.grid.column.time_window_interval_length": "Időablak órákban",
    "pages.orderList.grid.column.payment_method_name": "Fizetés módja",
    "pages.orderList.grid.column.payment_status_title": "Fizetési státusz",
    "pages.orderList.grid.column.shipment_statuses": "Logisztikai státusz",
    "pages.orderList.grid.column.status": "Megrendelési státusz",
    "pages.orderList.grid.column.pickup_point_id": "Átvevőpont azonosítója",
    "pages.orderList.grid.column.user_agent_type": "Platform",
    "pages.orderList.grid.column.fulfillment": "Flagelés",
    "pages.orderList.grid.column.card_number": "R+ kártyaszám",
    "pages.orderList.grid.column.order_subscription_id": "Előfizetés ID",
    "pages.orderList.grid.column.is_subscription": "Előfizetés?",
    "pages.orderList.title": "Megrendelések",
    "pages.orderList.selectAll": "Összes kijelölése",
    "pages.orderList.print": "Kijelölt rendelések nyomtatása",
    "pages.orderList.export": "Kijelölt rendelések exportálása",
    "pages.orderList.exportFiltered": "Exportálás szűrés alapján",
    "pages.orderList.sum": "Összesen: {count} rendelés, {sum} összegben.",
    "pages.orderList.exportFilteredSuccess": "A megrendelés exportot elküldjük emailben, amint elkészül.",

    "pages.order.edit.onSubmit.success": "Megrendelés szerkesztése sikeres!",
    "pages.order.edit.onSubmit.error": "Megrendelés szerkesztése sikertelen!",
    "pages.order.edit.breadcrumb": "Megrendelések",
    "pages.order.form.title": "Megrendelés: {orderId}",
    "pages.order.form.label.increment_id": "ID",
    "pages.order.form.label.created_at": "Megrendelés időpontja",
    "pages.order.form.label.userName": "Felhasználó / kapcsolattartó neve",
    "pages.order.form.label.email": "E-mail",
    "pages.order.form.label.phoneNumber": "Telefonszám",
    "pages.order.form.label.status": "Megrendelési státusz",
    "pages.order.form.label.paymentStatus": "Fizetési státusz",
    "pages.order.form.label.invoiceStatus": "Számlázási státusz",
    "pages.order.form.label.shippingMethod": "Áruátvétel módja",
    "pages.order.form.label.deliveryDate": "Szállítás ideje",
    "pages.order.form.label.shippingAddress": "Szállítás cím",
    "pages.order.form.label.billingAddress": "Számlázási cím",
    "pages.order.form.label.paymentMethodName": "Fizetés módja",
    "pages.order.form.label.grand_total": "Bruttó végösszeg",
    "pages.order.form.label.shippingTime": "Szállítás ideje",
    "pages.order.form.label.logisticsStatus": "Logisztikai státusz",
    "pages.order.form.label.deliveryTime": "Áruátvétel időpontja",
    "pages.order.form.label.shippingNote": "Vásárlói megjegyzés",
    "pages.order.form.label.anum": "Engedélyszám",
    "pages.order.form.label.user_agent_type": "Platform",
    "pages.order.form.label.app_version": "Verziószám",
    "pages.order.form.label.tracking_numbers": "Nyomonkövetési számok",
    "pages.order.form.label.giftCardPaid": "Ajándékkártya beváltás",
    "pages.order.form.adminNoteLabel": "Adminisztrátor megjegyzése",
    "pages.order.form.adminNoteHeading": "Adminisztrátori megjegyzések",
    "pages.order.form.label.orderedItems": "Rendelt tételek",
    "pages.order.form.label.orderedProducts": "Rendelt termékek",
    "pages.order.form.label.netTotal": "Nettó összérték",
    "pages.order.form.label.grossTotal": "Bruttó összérték",
    "pages.order.form.label.amountRefunded": "Visszatérített összeg",
    "pages.order.form.label.downloadInvoices": "Számla/számlák letöltése",
    "pages.order.form.label.timeWindowIntervalLength": "Időablak órákban",
    "pages.order.form.label.no_package": "Csomagolásmentes kiszállítás",
    "pages.order.form.label.onlineAmountRefunded": "Visszatérített összeg (online)",
    "pages.order.form.label.giftCardAmountRefunded": "Visszatérített összeg (ajándékkártyás)",
    "pages.order.form.tabs.data": "Adatok",
    "pages.order.form.tabs.products": "Termékek",
    "pages.order.form.tabs.payments": "Fizetések",
    "pages.order.form.tabs.log": "Napló",
    "pages.order.form.functionDisabled": "Ez a funkció nem elérhető",
    "pages.order.form.showOnMap": "Mutasd a térképen",
    "pages.order.form.discountsLabel": "Kedvezmények",
    "pages.order.form.trackingButtonLabel": "Csomag nyomonkövetése",
    "pages.order.form.refundButtonLabel": "Visszatérítés",
    "pages.order.form.refundDialog.title": "Rendelés visszatérítése",
    "pages.order.form.refundDialog.description": "Biztos, hogy visszatéríti a rendelést?",
    "pages.order.form.refundDialog.leftButtonLabel": "Mégsem",
    "pages.order.form.refundDialog.rightButtonLabel": "Visszatérítés",
    "pages.order.form.bankTransferPaidLabel": "Beérkezett összeg",
    "pages.order.form.pickupPointId": "Átvevőpont azonosítója: {pickupPointId}",
    "pages.order.form.pickupPointAddress": "Átvevőpont címe: {pickupPointAddress}",

    "pages.order.form.payments.transactions.title": "Online fizetéses tranzakciók",
    "pages.order.form.payments.transactions.column.id": "ID",
    "pages.order.form.payments.transactions.column.pmgw_transaction_id": "PMGW ID",
    "pages.order.form.payments.transactions.column.provider_transaction_id": "Provider ID",
    "pages.order.form.payments.transactions.column.anum": "Engedélyszám",
    "pages.order.form.payments.transactions.column.payment_method": "Fizetési mód",
    "pages.order.form.payments.transactions.column.status_payment": "Státusz",
    "pages.order.form.payments.transactions.column.amount": "Összeg",
    "pages.order.form.payments.transactions.column.amount_refund": "Visszatérített összeg",
    "pages.order.form.payments.transactions.column.success": "Sikeres?",

    "pages.order.form.payments.giftCardPayments.title": "Ajándékkártyás tranzakciók",
    "pages.order.form.payments.giftCardPayments.column.barcode": "Vonalkód",
    "pages.order.form.payments.giftCardPayments.column.amount": "Összeg",
    "pages.order.form.payments.giftCardPayments.column.voided_amount": "Visszatérített összeg",
    "pages.order.form.payments.giftCardPayments.column.paid_at": "Fizetés dátuma",
    "pages.order.form.payments.giftCardPayments.column.voided_at": "Visszatérítés dátuma",

    "pages.order.form.payments.invoicePayments.title": "Számlák",
    "pages.order.form.payments.invoicePayments.column.type": "Típus",
    "pages.order.form.payments.invoicePayments.column.invoice_number": "Számlaszám",
    "pages.order.form.payments.invoicePayments.column.created_at": "Létrehozás dátuma",
    "pages.order.form.payments.invoicePayments.column.invoiced_at": "Kiállítás dátuma",

    "pages.order.form.adminNotes.createdAtColumn": "Dátum",
    "pages.order.form.adminNotes.noteColumn": "Megjegyzés",
    "pages.order.form.adminNotes.createdByColumn": "Hozzáadta",
    "pages.order.form.adminNoteAdded": "Hozzáadás sikeres",

    "pages.order.form.log.whenColumn": "Dátum",
    "pages.order.form.log.infoColumn": "Információ",

    "pages.order.form.log.info.evoSentAt": "Visszaigazolta az EVO",
    "pages.order.form.log.info.shipmentSentAt": "Átadva a logappnak",
    "pages.order.form.log.info.shipmentDeliveryInProgressAt": "delivery_in_progress logisztikai státuszt kapott",

    "pages.order.form.buttons.closeOrder": "Zárás",
    "pages.order.form.buttons.correctInvoice": "Helyesbítő",
    "pages.order.form.buttons.storno": "Sztornó",
    "pages.order.form.buttons.refund": "Visszatérítés",

    "pages.order.edit.closeOrderModal.title": "Megrendelés zárása",
    "pages.order.edit.closeOrderModal.description": "Biztosan le szeretné zárni a megrendelést? Státusz: {status}",
    "pages.order.edit.closeOrderModal.status.delivered": "Sikeres",
    "pages.order.edit.closeOrderModal.status.undelivered": "Sikertelen",
    "pages.order.edit.closeOrderModal.closeDelivered": "Sikeres lezárás",
    "pages.order.edit.closeOrderModal.closeUndelivered": "Sikertelen lezárás",
    "pages.order.closeOrder.success": "Megrendelés zárása sikeres!",
    "pages.order.closeOrder.error": "Megrendelés zárása sikertelen!",

    "pages.order.edit.stornoModal.title": "Megrendelés sztornózása",
    "pages.order.edit.stornoModal.description": "Biztosan sztornózza a megrendelést?",
    "pages.order.storno.success": "Megrendelés sztornózása sikeres!",
    "pages.order.storno.error": "Megrendelés sztornózása sikertelen!",

    "pages.order.edit.refundModal.title": "Megrendelés visszatérítése",
    "pages.order.edit.refundModal.description": "Biztosan visszatéríti a megrendelést?",
    "pages.order.refund.success": "Megrendelés visszatérítése sikeres!",
    "pages.order.refund.error": "Megrendelés visszatérítése sikertelen!",

    "pages.orderItemList.grid.column.lfdnr": "Azonosító",
    "pages.orderItemList.grid.column.ean": "EAN",
    "pages.orderItemList.grid.column.productName": "Termék név",
    "pages.orderItemList.grid.column.unit_price": "Ár",
    "pages.orderItemList.grid.column.quantity": "Mennyiség",
    "pages.orderItemList.grid.column.quantity_delivered": "Kiszállított mennyiség",
    "pages.orderItemList.grid.column.quantity_missing": "Hiányzó mennyiség",
    "pages.orderItemList.grid.column.sub_total": "Összesen",
    "pages.orderItemList.deliveryPlaceholder": "Szállítás",

    "pages.giftCardOrderList.grid.column.id": "ID",
    "pages.giftCardOrderList.grid.column.created_at": "Megrendelés időpontja",
    "pages.giftCardOrderList.grid.column.user_fullname": "Felhasználó",
    "pages.giftCardOrderList.grid.column.user_email": "E-mail cím",
    "pages.giftCardOrderList.grid.column.grand_total": "Összeg",
    "pages.giftCardOrderList.grid.column.payment_method_name": "Fizetés módja",
    "pages.giftCardOrderList.grid.column.payment_status_title": "Fizetési státusz",
    "pages.giftCardOrderList.grid.column.status": "Megrendelési státusz",
    "pages.giftCardOrderList.grid.column.user_agent_type": "Platform",
    "pages.giftCardOrderList.title": "Digitális ajándékkártya",
    "pages.giftCardOrderList.selectAll": "Összes kijelölése",
    "pages.giftCardOrderList.print": "Kijelölt rendelések nyomtatása",
    "pages.giftCardOrderList.export": "Kijelölt rendelések exportálása",
    "pages.giftCardOrderList.exportFiltered": "Exportálás szűrés alapján",
    "pages.giftCardOrderList.sum": "Összesen: {count} rendelés, {sum} összegben.",
    "pages.giftCardOrderList.exportFilteredSuccess": "A digitális ajándékkártya exportot elküldjük emailben, amint elkészül.",

    "pages.giftCardOrder.info.breadcrumb": "Digitális ajándékkártyák",
    "pages.giftCardOrder.info.title": "Megrendelés: {id}",
    "pages.giftCardOrder.info.tabs.data": "Adatok",
    "pages.giftCardOrder.info.tabs.items": "Tételek",
    "pages.giftCardOrder.info.tabs.giftCards": "Ajándékkártyák",
    "pages.giftCardOrder.info.tabs.log": "Napló",

    "pages.giftCardOrder.info.label.id": "ID",
    "pages.giftCardOrder.info.label.code": "Kód",
    "pages.giftCardOrder.info.label.created_at": "Megrendelés időpontja",
    "pages.giftCardOrder.info.label.userName": "Felhasználó / kapcsolattartó neve",
    "pages.giftCardOrder.info.label.email": "E-mail",
    "pages.giftCardOrder.info.label.phoneNumber": "Telefonszám",
    "pages.giftCardOrder.info.label.status": "Megrendelési státusz",
    "pages.giftCardOrder.info.label.billingAddress": "Számlázási cím",
    "pages.giftCardOrder.info.label.paymentMethodName": "Fizetés módja",
    "pages.giftCardOrder.info.label.grand_total": "Bruttó végösszeg",
    "pages.giftCardOrder.info.label.paymentStatus": "Fizetési státusz",
    "pages.giftCardOrder.info.label.invoiceStatus": "Számlázási státusz",
    "pages.giftCardOrder.info.label.user_agent_type": "Platform",
    "pages.giftCardOrder.info.label.transactionId": "Tranzakciós azonosító",
    "pages.giftCardOrder.info.label.anum": "Engedélyszám",
    "pages.giftCardOrder.info.label.amountRefunded": "Visszatérített összeg",

    "pages.giftCardOrder.info.label.orderedItems": "Rendelt tételek",
    "pages.giftCardOrder.info.label.orderedProducts": "Rendelt ajándékkártyák",
    "pages.giftCardOrder.resend": "Újraküldés",
    "pages.giftCardOrder.resendModalTitle": "Ajándékkártya újraküldése",
    "pages.giftCardOrder.logModalTitle": "Napló",
    "pages.giftCardOrderItemList.inspectModalTitle": "Címzett(ek)",
    "pages.giftCardOrderItemList.grid.column.id": "ID",
    "pages.giftCardOrderItemList.grid.column.quantity": "Mennyiség",
    "pages.giftCardOrderItemList.grid.column.value": "Érték",
    "pages.giftCardOrderItemList.grid.column.design": "Dizájn ID",
    "pages.giftCardOrderItemList.grid.column.sub_total": "Összesen",
    "pages.giftCardOrderItemList.grid.column.name_from": "Feladó neve",
    "pages.giftCardOrderItemList.grid.column.name_to": "Ajándékozott neve",
    "pages.giftCardOrderItemList.grid.column.mail_to": "Ajándékozott(ak) e-mail címe(i)",
    "pages.giftCardOrderItemList.info": "A címzett(ek) megjelenítéséhez kattintson egy sorra!",

    "pages.giftCardList.grid.column.value": "Érték",
    "pages.giftCardList.grid.column.barcode": "Vonalkód",
    "pages.giftCardList.grid.column.expire_at": "Lejárati dátum",
    "pages.giftCardList.grid.column.mail_to": "Címzett",
    "pages.giftCardList.grid.column.sent_at": "Kiküldés dátuma",
    "pages.giftCardList.grid.column.resend": "Újraküldés",
    "pages.giftCardList.grid.column.log": "Napló",
    "pages.giftCardList.errors.format": "Az e-mail formátuma helytelen!",
    "pages.giftCardList.errors.cantBeEmpty": "Kötelező mező!",
    "pages.giftCardList.resendSucceed": "Ajándékkártya újraküldése sikeres!",
    "pages.giftCardList.resendError": "Ajándékkártya újraküldése sikertelen!",
    "pages.giftCardList.itemToResendTitle": "Ajándékozott e-mail címe",
    "pages.giftCardList.logs.grid.column.date": "Dátum",
    "pages.giftCardList.logs.grid.column.email": "E-mail",

    "pages.systemSettingList.title": "Rendszerparaméterek",

    "pages.storeOrderList.functions.updateDelivered": "Csomag megérkezett státuszba állítása",
    "pages.storeOrderList.functions.printOrders": "Rendelések nyomtatása",
    "pages.storeOrderList.functions.updateHanded": "Vásárló átvette státusz beállítása",
    "pages.storeOrderList.handed.true": "Átvéve",
    "pages.storeOrderList.handed.false": "Megtagadva",
    "pages.storeOrderList.handed.null": "Még nincs átvéve",

    "pages.order.delete.title": "Megrendelés törlése",
    "pages.order.delete.confirm": "Biztosan törölni szeretné a megrendelést?",
    "pages.order.delete.success": "Megrendelés törlése sikeres!",
    "pages.order.delete.error": "Megrendelés törlése sikertelen!",

    "pages.order.correct.success": "Helyesbítő számla létrehozása sikeres!",
    "pages.order.correct.error": "Helyesbítő számla létrehozása sikertelen!",

    "pages.storeOrderList.grid.column.increment_id": "Rendelés",
    "pages.storeOrderList.grid.column.created_at.long": "Vásárlás ideje",
    "pages.storeOrderList.grid.column.created_at.short": "Vásárlás i.",
    "pages.storeOrderList.grid.column.billing_fullname": "Számlázási név",
    "pages.storeOrderList.grid.column.shipping_fullname": "Szállítási név",
    "pages.storeOrderList.grid.column.delivery_day_expected.short": "Csomag várh. érk.",
    "pages.storeOrderList.grid.column.delivery_day_expected.long": "Csomag várható érkezése",
    "pages.storeOrderList.grid.column.packages": "Db",
    "pages.storeOrderList.grid.column.is_in_store.short": "Csom. megérk.",
    "pages.storeOrderList.grid.column.is_in_store.long": "Csomag megérkezett",
    "pages.storeOrderList.grid.column.store_admin_is_took_over": "Vásárló átvétel státusz",
    "pages.storeOrderList.grid.column.store_admin_is_took_over.null": "Még nincs átvéve",
    "pages.storeOrderList.grid.column.store_admin_is_took_over.true": "Átvéve",
    "pages.storeOrderList.grid.column.store_admin_is_took_over.false": "Megtagadva",
    "pages.storeOrderList.grid.column.delivered_at.short": "Átvétel időp.",
    "pages.storeOrderList.grid.column.delivered_at.long": "Átvétel időpontja",
    "pages.storeOrderList.grid.column.shipment_status": "Logisztikai státusz",
    "pages.storeOrderList.title.open": "Bolti átvételek / Nyitott rendelések",
    "pages.storeOrderList.title.closed": "Bolti átvételek / Lezárt rendelések",
    "pages.storeOrderList.tabsTitles.open": "Nyitott rendelések",
    "pages.storeOrderList.tabsTitles.closed": "Lezárt rendelések",
    "pages.storeOrderList.sendFunction": "Elküld",
    "pages.storeOrderList.updateDeliveredSucceed": "Csomag megérkezett státusz módosítása sikeres!",
    "pages.storeOrderList.updateHandedSucceed": "Vásárló átvette státusz módosítása sikeres!",
    "pages.storeOrderList.updateHandedError": "Csak boltba megérkezett rendelések státuszát lehet változtatni!",
    "pages.storeOrderList.notStoreAdminError": "Nincs hozzárendelt bolt, kérjük szóljon az adminisztrátornak!",

    "pages.websiteNotificationList.title": "Figyelmeztető sávok",
    "pages.websiteNotificationList.addButton": "Új info sáv",
    "pages.websiteNotificationList.grid.column.id": "ID",
    "pages.websiteNotificationList.grid.column.name": "Info sáv megnevezése",
    "pages.websiteNotificationList.grid.column.active_from": "Megjelenés kezdete",
    "pages.websiteNotificationList.grid.column.active_to": "Megjelenés vége",
    "pages.websiteNotification.onCreateSubmit.succeed": "Figyelmeztető sáv létrehozása sikeres!",
    "pages.websiteNotification.onCreateSubmit.error": "Figyelmeztető sáv létrehozása sikertelen!",
    "pages.websiteNotification.onEditSubmit.succeed": "Figyelmeztető sáv módosítása sikeres!",
    "pages.websiteNotification.onEditSubmit.error": "Figyelmeztető sáv módosítása sikertelen!",
    "pages.websiteNotification.createBreadcrumb": "Figyelmeztető sávok",
    "pages.websiteNotification.new": "Új figyelmeztető sáv",
    "pages.websiteNotification.form.idLabel": "ID",
    "pages.websiteNotification.form.nameLabel": "Figyelmeztető sáv megnevezése",
    "pages.websiteNotification.form.titleLabel": "Cím",
    "pages.websiteNotification.form.messageLabel": "Figyelmeztető sáv szöveg",
    "pages.websiteNotification.form.activeFromLabel": "Megjelenés kezdete",
    "pages.websiteNotification.form.activeToLabel": "Megjelenés vége",
    "pages.websiteNotification.form.target": "Platformok",
    "pages.websiteNotification.editBreadcrumb": "Figyelmeztető sávok",
    "pages.websiteNotification.edit.deleteSucceed": "Figyelmeztető sáv törlése sikeres!",
    "pages.websiteNotification.edit.deleteError": "Figyelmeztető sáv törlése sikertelen!",
    "pages.websiteNotification.edit.deleteModal.title": "Figyelmeztető sáv törlése",
    "pages.websiteNotification.edit.deleteModal.description": "Biztosan törölni szeretné a figyelmeztető sávot?",

    "pages.calendarDaysList.title": "Ünnepnapok",
    "pages.calendarDaysList.addButton": "Új ünnepnap",
    "pages.calendarDaysList.grid.column.date": "Dátum",
    "pages.calendarDaysList.grid.column.type": "Típus",
    "pages.calendarDaysList.grid.column.isDelivery": "Kiszállítás szünetel",

    "pages.calendarDay.editBreadcrumb": "Ünnepnapok",
    "pages.calendarDay.onEditSubmit.succeed": "Ünnepnap szerkesztése sikeres!",
    "pages.calendarDay.onEditSubmit.error": "Ünnepnap szerkesztése sikertelen!",
    "pages.calendarDay.onCreateSubmit.succeed": "Ünnepnap létrehozása sikeres!",
    "pages.calendarDay.onCreateSubmit.error": "Ünnepnap létrehozása sikertelen!",
    "pages.calendarDay.new": "Új ünnepnap",
    "pages.calendarDay.edit.deleteSucceed": "Ünnepnap törlése sikeres!",
    "pages.calendarDay.edit.deleteFailed": "Ünnepnap törlése sikertelen!",
    "pages.calendarDay.form.dateLabel": "Dátum",
    "pages.calendarDay.form.isWorkdayLabel": "Típus",
    "pages.calendarDay.form.workday": "Munkanap",
    "pages.calendarDay.form.holiday": "Munkaszüneti nap",
    "pages.calendarDay.form.isDeliveryLabel": "Kiszállítás szünetel",
    "pages.calendarDay.edit.deleteModal.title": "Ünnepnap törlése",
    "pages.calendarDay.edit.deleteModal.description": "Biztosan törli az ünnepnapot?",

    "pages.productParamList.title": "Termékjellemzők",
    "pages.productParamList.grid.column.id": "Jellemzó kód",
    "pages.productParamList.grid.column.name": "Jellemző név",
    "pages.productParamList.grid.column.is_filter": "Szűrésben megjelenik",
    "pages.productParamList.grid.column.created_at": "Létrehozás dátuma",
    "pages.productParam.onEditSubmit.succeed": "Termékjellemző szerkesztése sikeres!",
    "pages.productParam.onEditSubmit.error": "Termékjellemző szerkesztése sikertelen!",
    "pages.productParam.editBreadcrumb": "Termékjellemzők",
    "pages.productParam.form.nameLabel": "Termékjellemző neve",
    "pages.productParam.form.nameLabelEvo": "Termékjellemző neve (Forrás: ERP)",
    "pages.productParam.form.isActiveLabel": "Aktív",
    "pages.productParam.form.isFilterLabel": "Szűrésben megjelenik",
    "pages.productParam.form.optionsTitle": "Termékjellemző opciói",
    "pages.productParam.form.optionLabel": "Opció {number} neve",
    "pages.productParam.form.erpLabel": "Opció {number} neve (Forrás: ERP)",

    "pages.logList.title": "Admin naplózás",
    "pages.logList.subtitle": "A változások megtekintéséhez kattints egy sorra!",
    "pages.logList.grid.column.modelId": "Azonosító",
    "pages.logList.grid.column.action": "Művelet típusa",
    "pages.logList.grid.column.parentType": "Modul",
    "pages.logList.grid.column.ean": "EAN",
    "pages.logList.grid.column.incrementId": "Rendelésszám",
    "pages.logList.grid.column.objectType": "Objektum",
    "pages.logList.grid.column.adminName": "Módosító",
    "pages.logList.grid.column.timeStamp": "Módosítás dátuma",
    "pages.logList.modalTitle": "Változás",

    "pages.productBundles.title": "Virtuális termékcsomagok",
    "pages.productBundles.addButton": "Új termékcsomag",
    "pages.productBundles.grid.column.id": "ID",
    "pages.productBundles.grid.column.lfdnr": "CSZ",
    "pages.productBundles.grid.column.name": "Csomag neve",
    "pages.productBundles.grid.column.mainCategory": "Főkategória",
    "pages.productBundles.grid.column.category": "Közvetlen kategória",
    "pages.productBundles.grid.column.status": "Státusz",
    "pages.productBundles.grid.column.createdAt": "Létrehozás dátuma",

    "pages.productBundle.breadcrumb.title": "Virtuális termékcsomagok",
    "pages.productBundle.form.nameLabel": "Csomag neve",
    "pages.productBundle.form.promotionLabel": "Promóció azonosító (ID)",
    "pages.productBundle.form.slugLabel": "URL",
    "pages.productBundle.form.brandLabel": "Márka",
    "pages.productBundle.form.descriptionLabel": "Leírás",
    "pages.productBundle.form.imagesHeading": "Termékképek",
    "pages.productBundle.form.productsHeading": "Termékek",
    "pages.productBundle.form.statusLabel": "Státusz a websiteon",
    "pages.productBundle.form.isActiveLabel": "Aktív",
    "pages.productBundle.form.ogImageLabel": "og:image",
    "pages.productBundle.form.ogTitleLabel": "og:title",
    "pages.productBundle.form.ogDescriptionLabel": "og:description",
    "pages.productBundle.form.metaDescriptionLabel": "meta description",

    "pages.productBundle.create.title": "Új termékcsomag létrehozása",
    "pages.productBundle.onSubmit.success": "A termékcsomag módosítása sikeres!",
    "pages.productBundle.onSubmit.error": "A termékcsomag módosítása sikertelen!",

    "pages.productBundle.new": "Új termékcsomag létrehozása",
    "pages.productBundle.onCreateSubmit.success": "A termékcsomag létrehozása sikeres!",
    "pages.productBundle.onCreateSubmit.error": "A termékcsomag létrehozása sikertelen!",

    "pages.maintenanceList.title": "Karbantartások",
    "pages.maintenanceList.addButton": "Új karbantartás",
    "pages.maintenanceList.grid.column.id": "ID",
    "pages.maintenanceList.grid.column.active_from": "Érvényesség kezdete",
    "pages.maintenanceList.grid.column.active_to": "Érvényesség vége",
    "pages.maintenanceList.grid.column.scopes": "Hatáskörök",
    "pages.maintenanceList.grid.column.notification": "Szöveg",
    "pages.maintenanceList.grid.column.maintenance": "Karbantartás",
    "pages.maintenanceList.grid.column.is_active": "Aktív",
    "pages.maintenance.onCreateSubmit.succeed": "Karbantartás létrehozása sikeres!",
    "pages.maintenance.onCreateSubmit.error": "Karbantartás létrehozása sikertelen!",
    "pages.maintenance.onEditSubmit.succeed": "Karbantartás szerkesztése sikeres!",
    "pages.maintenance.onEditSubmit.error": "Karbantartás szerkesztése sikertelen!",
    "pages.maintenance.createBreadcrumb": "Karbantartások",
    "pages.maintenance.editBreadcrumb": "Karbantartások",
    "pages.maintenance.new": "Új karbantartás",
    "pages.maintenance.form.idLabel": "ID",
    "pages.admin.form.scopes": "Hatáskörök",
    "pages.maintenance.form.maintenanceLabel": "Karbantartás",
    "pages.maintenance.form.isActiveLabel": "Aktív",
    "pages.maintenance.form.activeFromLabel": "Érvényesség kezdete",
    "pages.maintenance.form.activeToLabel": "Érvényesség vége",
    "pages.maintenance.form.notification.label": "Szöveg",
    "pages.maintenance.edit.deleteModal.title": "Karbantartás törlése",
    "pages.maintenance.edit.deleteModal.description": "Biztosan törölni szeretné a karbantartást?",
    "pages.maintenance.edit.deleteSucceed": "Karbantartás törlése sikeres!",
    "pages.maintenance.edit.deleteError": "Karbantartás törlése sikertelen!",

    "pages.todoList.title": "Todo-k",
    "pages.todoList.grid.column.id": "ID",
    "pages.todoList.grid.column.status": "Státusz",
    "pages.todoList.grid.column.subject": "Tárgy",
    "pages.todoList.grid.column.message": "Üzenet",
    "pages.todoList.grid.column.role_name": "Szerepkör",
    "pages.todoList.grid.column.assigned_to_name": "Felhasználó",
    "pages.todoList.grid.column.created_at": "Létrehozás dátuma",
    "pages.todoList.grid.column.updated_at": "Módosítás dátuma",
    "pages.todo.onEditSubmit.succeed": "Todo szerkesztése sikeres!",
    "pages.todo.onEditSubmit.error": "Todo szerkesztése sikertelen!",
    "pages.todo.editBreadcrumb": "Todo-k",
    "pages.todo.form.idLabel": "ID",
    "pages.todo.form.statusLabel": "Státusz",
    "pages.todo.form.assignedToLabel": "Felhasználó",
    "pages.todo.form.subjectLabel": "Tárgy",
    "pages.todo.form.messageLabel": "Üzenet",
    "pages.todo.form.roleLabel": "Szerepkör",
    "pages.menuList.title": "Menüszerkesztő",
    "pages.menuList.addButton": "Új menü",
    "pages.menuList.grid.column.id": "ID",
    "pages.menuList.grid.column.name": "Menü címe",
    "pages.menuList.grid.column.slug": "Slug",
    "pages.menuList.grid.column.created_at": "Létrehozás dátuma",
    "pages.menuList.grid.column.updated_at": "Módosítás dátuma",
    "pages.menuList.grid.column.is_visible": "Aktív",
    "pages.menu.onCreateSubmit.succeed": "Menü létrehozása sikeres!",
    "pages.menu.onCreateSubmit.error": "Menü létrehozása sikertelen!",
    "pages.menu.onEditSubmit.succeed": "Menü szerkesztése sikeres!",
    "pages.menu.onEditSubmit.error": "Menü szeresztése sikertelen!",
    "pages.menu.form.idLabel": "ID",
    "pages.menu.form.nameLabel": "Menü neve",
    "pages.menu.form.slugLabel": "Slug",
    "pages.menu.form.isActiveLabel": "Aktív",
    "pages.menu.form.activeFromLabel": "Megjelenés kezdete",
    "pages.menu.form.activeToLabel": "Megjelenés vége",
    "pages.menu.form.menuItemFormModal.isActiveLabel": "Aktív",
    "pages.menu.new": "Új menü",
    "pages.menu.createBreadcrumb": "Menüszerkesztő",
    "pages.menu.editBreadcrumb": "Menüszerkesztő",
    "pages.menu.form.menuTable.newRowWithTitle1": "Új menüpont hozzáadása ",
    "pages.menu.form.menuTable.newRowWithTitle2": " alá",
    "pages.menu.form.menuTable.newRow": "Új menüpont hozzáadása",
    "pages.menu.form.menuTable.undelete": "Visszaállítás",

    "pages.menu.form.menuItemFormModal.title.create": "Új menüpont",
    "pages.menu.form.menuItemFormModal.title.edit": "Menüpont szerkesztése",
    "pages.menu.form.menuItemFormModal.saveButton": "JÓVÁHAGYÁS",
    "pages.menu.form.menuItemFormModal.titleLabel": "Menüpont címe",
    "pages.menu.form.menuItemFormModal.urlLabel": "Link",
    "pages.menu.form.menuItemFormModal.popupLabel": "Megnyitás új ablakban",

    "pages.menu.form.menuItemFormModal.urlOptionType.page": "Oldalak",
    "pages.menu.form.menuItemFormModal.urlOptionType.productCategory": "Termékkategóriák",
    "pages.menu.form.menuItemFormModal.urlOptionType.product": "Termékek",
    "pages.menu.form.menuItemFormModal.urlOptionType.productList": "Termékszegmensek",
    "pages.menu.form.menuItemFormModal.urlOptionType.productBrand": "Márkák",
    "pages.menu.form.menuItemFormModal.urlOptionType.article": "Cikkek",
    "pages.menu.form.menuItemFormModal.moreOptions": "+ további {moreCount} találat (kérjük szűkítsen link beírásával)",
    "pages.menu.form.menuItemFormModal.pageIdOverlayLabel": "Miniatűroldal link",
    "pages.menu.edit.deleteModal.title": "Menü törlése",
    "pages.menu.edit.deleteModal.description": "Biztosan törölni szeretné az menüt?",
    "pages.menu.edit.deleteSucceed": "Menü törlése sikeres!",
    "pages.menu.edit.deleteError": "Menü törlése sikertelen!",

    "pages.productVariantList.title": "Variánsok",
    "pages.productVariantList.addButton": "Új variáns",
    "pages.productVariantList.grid.column.id": "ID",
    "pages.productVariantList.grid.column.lfdnr": "CSZ",
    "pages.productVariantList.grid.column.name": "Variáns neve",
    "pages.productVariantList.grid.column.main_categories": "Főkategória",
    "pages.productVariantList.grid.column.categories": "Közvetlen kategória",
    "pages.productVariantList.grid.column.is_visible": "Állapot",
    "pages.productVariantList.grid.column.created_at": "Hozzáadás dátuma",

    "pages.productVariant.onCreateSubmit.succeed": "Variáns létrehozása sikeres!",
    "pages.productVariant.onCreateSubmit.error": "Variáns létrehozása sikertelen!",
    "pages.productVariant.createBreadcrumb": "Variánsok",
    "pages.productVariant.new": "Új variáns",

    "pages.productVariant.form.idLabel": "ID",
    "pages.productVariant.form.productParamIdInfo": "Válassza ki azt a tulajdonságo(ka)t, melyek mentén a variánsok képezhetőek.",
    "pages.productVariant.form.productParamIdLabel": "Összekötő termék paraméter",
    "pages.productVariant.form.isActiveLabel": "Aktív",
    "pages.productVariant.form.nameLabel": "Variáns neve",
    "pages.productVariant.form.nameHint": "Kötelező mező. A webshopban nem jelenik meg.",
    "pages.productVariant.form.primaryProductIdLabel": "Elsődlegesen megjelenő termék",
    "pages.productVariant.form.primaryProductIdHint": "Kötelező mező. A termékkártyán megjelenő név.",
    "pages.productVariant.form.childrenInfo": "Variánsok összerendelése manuálisan történik, termék listából való választással.",
    "pages.productVariant.form.addProductDialog.description": "Jelölje ki az alábbi termék listából azokat a termékeket, melyeket össze szeretne kötni variánsként.",
    "pages.productVariant.form.slugLabel": "URL",
    "pages.productVariant.form.activeFromLabel": "Megjelenés kezdete",
    "pages.productVariant.form.activeToLabel": "Megjelenés vége",
    "pages.productVariant.form.deleteModal.title": "Variáns törlése",
    "pages.productVariant.form.deleteModal.description": "Biztosan törlöd?",
    "pages.productVariant.form.deleteSuccess": "Variáns törlése sikeres!",
    "pages.productVariant.form.deleteError": "Variáns törlése sikertelen!",

    "pages.productVariant.editBreadcrumb": "Variánsok",

    "pages.productVariant.onEditSubmit.succeed": "Variáns szerkesztése sikeres!",
    "pages.productVariant.onEditSubmit.error": "Variáns szerkesztése sikertelen!",

    "pages.ratingList.title": "Értékelések",
    "pages.ratingList.grid.column.id": "ID",
    "pages.ratingList.grid.column.order_increment_id": "Rendelés ID",
    "pages.ratingList.grid.column.rating_courier": "Futár értékelés",
    "pages.ratingList.grid.column.note_courier": "Futár megjegyzés",
    "pages.ratingList.grid.column.rating_purchase": "Vásárlás értékelés",
    "pages.ratingList.grid.column.note_purchase": "Vásárlás megjegyzés",
    "pages.ratingList.grid.column.rating_recommend": "Ajánlás értékelés",
    "pages.ratingList.grid.column.created_at": "Létrehozás dátuma",
    "pages.ratingList.grid.column.updated_at": "Módosítás dátuma",
    "pages.ratingList.avgLabel.avg_rating_courier": "Átlagos futár értékelés",
    "pages.ratingList.avgLabel.avg_rating_purchase": "Átlagos vásárlás értékelés",
    "pages.ratingList.avgLabel.avg_rating_recommend": "Átlagos ajánlás értékelés",

    "pages.cacheManagement.title": "Cache kezelés",
    "pages.cacheManagement.cacheTitle": "Cache ürítés",
    "pages.cacheManagement.reindexTitle": "Termék Reindex",
    "pages.cacheManagement.cacheClearing.full": "Teljes ürítés",
    "pages.cacheManagement.cacheClearing.clearProductCache": "Termékek",
    "pages.cacheManagement.cacheClearing.clearProductCategoryCache": "Termékkategóriák",
    "pages.cacheManagement.cacheClearing.clearProductPriceCache": "Termékárak",

    "pages.cacheManagement.cacheClearing.full.success": "Teljes cache ürítés sikeresen elindítva!",
    "pages.cacheManagement.cacheClearing.full.error": "Teljes cache ürítés indítása sikertelen!",
    "pages.cacheManagement.cacheClearing.clearProductCache.success": "Termék cache ürítés sikeresen elindítva!",
    "pages.cacheManagement.cacheClearing.clearProductCache.error": "Termék cache ürítés indítása sikertelen!",
    "pages.cacheManagement.cacheClearing.clearProductCategoryCache.success": "Termékkategória-cache ürítés sikeresen elindítva!",
    "pages.cacheManagement.cacheClearing.clearProductCategoryCache.error": "Termékkategória-cache ürítés indítása sikertelen!",
    "pages.cacheManagement.cacheClearing.clearProductPriceCache.success": "Termékár cache ürítés sikeresen elindítva!",
    "pages.cacheManagement.cacheClearing.clearProductPriceCache.error": "Termékár cache ürítés indítása sikertelen!",

    "pages.cacheManagement.reindexing.queueProductIndex": "Teljes termék reindexelés",
    "pages.cacheManagement.reindexing.indexStatus": "Teljes termék reindex státusz",

    "pages.cacheManagement.reindexing.indexStatus.remaining": "Hátralévő reindexelendő termékek ({queueRefreshInterval} másodpercenként frissül)",
    "pages.cacheManagement.reindexing.indexStatus.refresh": "Frissítés",

    "pages.cacheManagement.reindexing.confirmModal.title": "Termék reindexelés",
    "pages.cacheManagement.reindexing.confirmModal.description": "Biztosan elindítja a termék reindexelést?",

    "pages.cacheManagement.reindexing.allProducts": "Teljes",
    "pages.cacheManagement.reindexing.selectedProducts": "Részleges",
    "pages.cacheManagement.reindexing.indexProduct": "Kiválasztott termékek reindexelése",
    "pages.cacheManagement.cacheClearing.selected.success": "Részleges reindexelés sikeres!",
    "pages.cacheManagement.cacheClearing.selected.error": "Részleges reindexelés sikertelen!",

    "pages.cacheManagement.cacheClearing.indexStatus.error": "Hátralévő termékek frissítése sikertelen!",

    "pages.cacheManagement.cacheClearing.queueProductIndex.success": "Termékekreindexelés sikeresen elindítva!",
    "pages.cacheManagement.cacheClearing.queueProductIndex.error": "Termékekreindexelés elindítása sikertelen!",

    "pages.cacheManagement.cacheClearing.confirmModal.title.full": "Teljes cache ürítés",
    "pages.cacheManagement.cacheClearing.confirmModal.title.clearProductCache": "Termék cache ürítés",
    "pages.cacheManagement.cacheClearing.confirmModal.title.clearProductCategoryCache": "Termékkategória-cache ürítés",
    "pages.cacheManagement.cacheClearing.confirmModal.title.clearProductPriceCache": "Termékár cache ürítés",
    "pages.cacheManagement.cacheClearing.confirmModal.description": "Biztosan üríti a cache-t?",
    "pages.campaignList.title": "Negyedéves kampányok",
    "pages.campaignList.addButton": "Új negyedéves kampány",
    "pages.campaignList.grid.column.id": "ID",
    "pages.campaignList.grid.column.name": "Cím",
    "pages.campaignList.grid.column.active_from": "Érvényesség kezdete",
    "pages.campaignList.grid.column.active_to": "Érvényesség vége",
    "pages.campaignList.grid.column.is_active": "Státusz",
    "pages.campaign.onCreateSubmit.succeed": "Negyedéves kampány létrehozása sikeres!",
    "pages.campaign.onCreateSubmit.error": "Negyedéves kampány létrehozása sikertelen!",
    "pages.campaign.onEditSubmit.succeed": "Negyedéves kampány szerkesztése sikeres!",
    "pages.campaign.onEditSubmit.error": "Negyedéves kampány szerkesztése sikertelen!",
    "pages.campaign.createBreadcrumb": "Negyedéves kampányok",
    "pages.campaign.new": "Új negyedéves kampány",
    "pages.campaign.form.nameLabel": "Cím",
    "pages.campaign.form.descriptionLabel": "Leírás",
    "pages.campaign.form.activeFromLabel": "Kezdés",
    "pages.campaign.form.activeToLabel": "Befejezés",
    "pages.campaign.form.isActiveLabel": "Aktív",
    "pages.campaign.form.targetUrlLabel": "CTA URL",
    "pages.campaign.form.targetTitleLabel": "CTA szöveg",
    "pages.campaign.imageForegroundDesktopLabel": "Desktop előtér",
    // TODO: Temporarily removed: https://bigfishhu.atlassian.net/browse/ROS-2442
    // "pages.campaign.imageBackgroundDesktopLabel": "Desktop háttér",
    "pages.campaign.imageForegroundMobileLabel": "Mobil előtér",
    // TODO: Temporarily removed: https://bigfishhu.atlassian.net/browse/ROS-2442
    // "pages.campaign.imageBackgroundMobileLabel": "Mobil háttér",
    "pages.campaign.editBreadcrumb": "Negyedéves kampányok",
    "pages.campaign.edit.deleteSucceed": "Negyedéves kampány törlése sikeres!",
    "pages.campaign.edit.deleteError": "Negyedéves kampány törlése sikertelen!",
    "pages.campaign.edit.deleteModal.title": "Negyedéves kampány törlése",
    "pages.campaign.edit.deleteModal.description": "Biztosan törölni szeretné a negyedéves kampányt?",
    "pages.campaign.form.images": "Képek",
    "pages.campaign.form.images.pngOnly": "Csak PNG kiterjesztésű képeket lehet feltölteni!",

    "pages.subscriptionList.title": "Pelenka-előfizetések",
    "pages.subscriptionList.grid.column.id": "ID",
    "pages.subscriptionList.grid.column.user_id": "Felh. ID",
    "pages.subscriptionList.grid.column.user_fullname": "Név",
    "pages.subscriptionList.grid.column.user_email": "E-mail",
    "pages.subscriptionList.grid.column.payment_provider": "Fizetési szolgáltató",
    "pages.subscriptionList.grid.column.created_at": "Létrehozás dátuma",
    "pages.subscriptionList.grid.column.updated_at": "Módosítás dátuma",
    "pages.subscriptionList.grid.column.deleted_at": "Törlés dátuma",
    "pages.subscriptionList.grid.column.shipping_method_name": "Szállítási mód",

    "pages.subscription.form.title": "Pelenka-előfizetés: {id}",
    "pages.subscription.edit.breadcrumb": "Pelenka-előfizetések",
    "pages.subscription.label.id": "ID",
    "pages.subscription.label.user": "Felhasználó neve",
    "pages.subscription.label.email": "E-mail",
    "pages.subscription.label.billing_address": "Számlázási cím",
    "pages.subscription.label.shipping_address": "Szállítási cím",
    "pages.subscription.label.created_at": "Létrehozás dátuma",
    "pages.subscription.label.updated_at": "Módosítás dátuma",
    "pages.subscription.label.paused_at": "Szüneteltetés dátuma",
    "pages.subscription.label.deleted_at": "Törlés dátuma",
    "pages.subscription.label.notified_at": "Értesítés dátuma",
    "pages.subscription.label.notified_at_sms": "SMS dátuma",
    "pages.subscription.label.date_next": "Következő szállítás dátuma",
    "pages.subscription.label.time_window_date": "Időablak (nap)",
    "pages.subscription.label.time_window_interval": "Időablak (idősáv)",
    "pages.subscription.label.time_window_changed": "Időablak változtatások",
    "pages.subscription.label.time_window_shipping_method": "Időablakos szállítási mód",

    "pages.subscription.label.items": "Termékek",
    "pages.subscription.form.items.column.id": "ID",
    "pages.subscription.form.items.column.product": "Termék",
    "pages.subscription.form.items.column.quantity": "Mennyiség",

    "pages.subscription.label.orders": "Megrendelések",
    "pages.subscription.form.orders.column.increment_id": "ID",
    "pages.subscription.form.orders.column.status": "Státusz",
    "pages.subscription.form.orders.column.delivey_day_expected": "Várható kiszállítás napja",
    "pages.subscription.form.orders.column.delivey_time_window": "Időablak",
    "pages.subscription.form.orders.column.created_at": "Létrehozás dátuma",
};
